import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import Subscribe from "../../Component/Subscribe/Subscribe";
import style from "./NewsPage.module.scss";
import apiService from "../../services/apiService";
import moment from "moment";
import Gallery from "../../Component/Gallery/Gallery";
import GalleryPopup from "../../Component/GalleryPopup/GalleryPopup";
import { LiteralsContext } from "../../context";
import Meta from "../../Component/Meta/Meta";
import ScriptTag from "react-script-tag";

class NewsPage extends React.Component {
  state = {
    popupIsOpen: false,
    imageId: 0,
    data: {
      gallery: null,
    },
    articles: {
      data: null,
    },
    isConstruction: this.props.match.path === "/construction-progress/:id",
  };

  componentDidMount() {
    this.fetchData();
    this.fetchNews();

    // var el=document.getElementsById('script');

    // var script=document.createElement('script');
    // script.src = "//fbstore.sendpulse.com/loader.js";
    // script.async = true;
    // script.setAttribute('data-sp-widget-id','38da452c-9317-4076-b4cf-26e6b3492489');

    // el.appendChild(script);
  }

  fetchData = () => {
    if (this.state.isConstruction) {
      apiService
        .constructionArticle(this.props.match.params.id)
        .then((resp) => {
          let gallery = null;
          if (resp.data.gallery) {
            gallery = resp.data.gallery.map((v) => v.full_url);
          }

          this.setState({
            data: { ...resp.data, gallery },
          });
        })
        .catch((error) => {
          console.error("fetchNewsPage", error);
          throw error;
        });
    } else {
      apiService
        .article(this.props.match.params.id)
        .then((resp) => {
          this.setState({
            data: resp.data,
          });
        })
        .catch((error) => {
          console.error("fetchNewsPage", error);
          throw error;
        });
    }
  };

  fetchNews = () => {
    apiService
      .articles(4)
      .then((resp) => {
        this.setState({
          articles: resp.data,
        });
      })
      .catch((error) => {
        console.error("fetchNews", error);
        throw error;
      });
  };

  popupOpen = (id) => {
    this.setState({
      popupIsOpen: true,
      imageId: id,
    });
    document.body.style.overflow = "hidden";
  };

  onPopupChange = () => {
    this.setState({
      popupIsOpen: !this.state.popupIsOpen,
    });
    document.body.style.overflow = "visible";
  };

  render() {
    const { data, articles, isConstruction } = this.state;
    const { social_links } = this.context;
    const filteredArticles =
      articles.data && articles.data.length
        ? articles.data.filter((z) => z.id !== this.state.data.id).slice(0, 3)
        : null;

    return (
      <>
        <Meta title={data.title} image={data.banner} />

        <div className={style.newsPage}>
          {this.state.popupIsOpen && (
            <GalleryPopup
              imageType={this.state.imageId}
              type="popup"
              handle={this.onPopupChange}
            />
          )}

          <Breadcrumbs
            current={data.title}
            steps={3}
            secondStep={isConstruction ? "ХІД БУДІВНИЦТВА" : "НОВИНИ ТА АКЦІЇ"}
            secondStepUrl={isConstruction ? "/construction-progress" : "/news"}
          />
          <div className={style.newsPageHeader}>
            {data.discount && (
              <div className={style.newsPageLabel}>{data.discount}</div>
            )}
            <div className={style.newsPageTitle}>{data.title}</div>
            <div className={style.newsPageDate}>
              {data.published_at &&
                moment(data.published_at).format("DD/MM/YYYY")}
            </div>
          </div>
          {data.banner && <img src={data.banner} className={style.newsImage} />}
          <div className={style.newsPageMain}>
            {data.content && (
              <div
                className={style.mainText}
                dangerouslySetInnerHTML={{
                  __html: data.content,
                }}
              />
            )}

            {data.content && (
              <div className={style.script}>
                <ScriptTag
                  src="//fbstore.sendpulse.com/loader.js"
                  data-sp-widget-id="38da452c-9317-4076-b4cf-26e6b3492489"
                  async
                />
              </div>
            )}
          </div>

          {data.gallery && (
            <div className={style.galleryWrapp}>
              <div className="landing-gallery-imagesWrapper">
                <div className="landing-gallery-nav">
                  <div className="gallery_nav-arrow" />
                  <div className="gallery_nav-arrowRotate" />
                </div>
              </div>
              <Gallery callback={this.popupOpen} slides={data.gallery} />
            </div>
          )}

          <div className={style.share}>
            <div className={style.shareText}>Поділитись:</div>
            {social_links.facebook && (
              <a
                target="_blank"
                href={social_links.facebook.content}
                className={style.socialFacebook}>
                <div className={style.facebookIcon} />
              </a>
            )}
            {social_links.instagram && (
              <a
                target="_blank"
                href={social_links.instagram.content}
                className={style.socialInstagram}>
                <div className={style.instagramIcon} />
              </a>
            )}
            {social_links.telegram && (
              <a
                target="_blank"
                href={social_links.telegram.content}
                className={style.socialTelegram}>
                <div className={style.telegramIcon} />
              </a>
            )}
            {social_links.youtube && (
              <a
                target="_blank"
                href={social_links.youtube.content}
                className={style.socialYoutube}>
                <div className={style.youtubeIcon} />
              </a>
            )}
          </div>
        </div>
        <div className={style.recNews}>
          <div className={style.recTitle}>Читайте також</div>
          <div className={style.newsWrapper}>
            {filteredArticles &&
              filteredArticles.map((item) => (
                <Link
                  to={`/news/${item.slug}`}
                  className={style.newsItem}
                  key={item.slug}>
                  <img src={item.banner} className={style.newsFirstImage} />
                  <div className={style.newsDate}>
                    {moment(item.published_at).format("DD/MM/YYYY")}
                  </div>
                  <div className={style.newsItemTitle}>{item.title}</div>
                  <div className={style.newsArrow} />
                </Link>
              ))}
          </div>
        </div>
        <Subscribe />
      </>
    );
  }
}
NewsPage.contextType = LiteralsContext;

export default NewsPage;
