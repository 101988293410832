import React from "react";
import cn from "classnames";
import ReactPlayer from "react-player";
import Gallery from "../../Component/Gallery/Gallery";
import style from "./Gallery.module.scss";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import GalleryPopup from "../../Component/GalleryPopup/GalleryPopup";
import apiService from "../../services/apiService";
import { LiteralsContext } from "../../context";
import Meta from "../../Component/Meta/Meta";

class GalleryPage extends React.Component {
  state = {
    tabValue: "gallery_exterior",
    popupIsOpen: false,
    imageId: 0,
    data: {
      meta: {},
      media: {},
    },
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    apiService
      .gallery()
      .then((resp) => {
        this.setState({
          data: resp.data,
        });
      })
      .catch((error) => {
        console.error("fetchGalleryPage", error);
        throw error;
      });
  };

  onPopupChange = () => {
    this.setState({
      popupIsOpen: !this.state.popupIsOpen,
      imageId: 0,
    });
    document.body.style.overflow = "visible";
  };

  popupOpen = (id) => {
    this.setState({
      popupIsOpen: true,
      imageId: id,
    });
    document.body.style.overflow = "hidden";
  };

  getCurrentImage = (id) => {
    this.setState({ imageId: id });
  };

  handleChange = (key) => {
    this.setState({ tabValue: key });
  };

  showTab = (key) => {
    return !!(this.state.data.media[key] && this.state.data.media[key].length);
  };

  rednerTabInfo = () => {
    const { meta, media } = this.state.data;
    return (
      <div className={style.backDiv}>
        <div className="landing-gallery-imagesWrapper">
          <div className="landing-gallery-nav">
            <div className="gallery_nav-arrow" />
            <div className="gallery_nav-arrowRotate" />
          </div>
        </div>
        <div>
          {this.showTab(this.state.tabValue) && (
            <Gallery
              callback={this.popupOpen}
              slides={media[this.state.tabValue]}
              key={this.state.tabValue}
            />
          )}
        </div>
        <div>
          <div className="landing-gallery-progressLine">
            <div className="landing-gallery-currentProgress" />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { meta, media } = this.state.data;
    return (
      <>
        <Meta title={this.state.data.title} />

        <div className={style.galleryPage}>
          {this.state.popupIsOpen && (
            <div className={style.test}>
              <GalleryPopup
                imageType={this.state.imageId}
                type="popup"
                handle={this.onPopupChange}
              />
            </div>
          )}
          <Breadcrumbs current={this.state.data.title} />
          <div className={style.galleryTop}>
            <div className={style.topLeft}>
              <div className={style.leftTitle}>{meta.first_block_title}</div>
              <div
                className={style.leftText}
                dangerouslySetInnerHTML={{
                  __html: meta.first_block_content,
                }}
              ></div>
            </div>
            <div className={style.topBottom}>
              <ReactPlayer
                className={style.player}
                url={`https://www.youtube.com/watch?v=${meta.first_block_video_id}`}
              />
            </div>
          </div>
          <div className={style.galleryBottom}>
            <div className={style.botomHeader}>
              {this.showTab("gallery_exterior") && (
                <div
                  className={cn(style.headerItemActive, {
                    [style.activeButton]:
                      this.state.tabValue === "gallery_exterior",
                  })}
                  onClick={() => this.handleChange("gallery_exterior")}
                >
                  {meta.exterior_tab_label}
                </div>
              )}
              {this.showTab("gallery_interior") && (
                <div
                  className={cn(style.headerItem, {
                    [style.activeButton]:
                      this.state.tabValue === "gallery_interior",
                  })}
                  onClick={() => this.handleChange("gallery_interior")}
                >
                  {meta.interior_tab_label}
                </div>
              )}
              {this.showTab("gallery_lobby") && (
                <div
                  className={cn(style.headerItem, {
                    [style.activeButton]:
                      this.state.tabValue === "gallery_lobby",
                  })}
                  onClick={() => this.handleChange("gallery_lobby")}
                >
                  {meta.lobby_tab_label}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={style.galleryWrapper}>{this.rednerTabInfo()}</div>
      </>
    );
  }
}
GalleryPage.contextType = LiteralsContext;

export default GalleryPage;
