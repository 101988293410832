import React from "react";
import { createBrowserHistory } from "../../history";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import houseImage from "../../Images/house.png";
import style from "./House.module.scss";
import imageMapResize from "image-map-resizer";

class HousePlan extends React.Component {
  componentDidMount() {
    imageMapResize("map");

    const areaList = document.querySelectorAll("area");

    for (let i = 0; i < areaList.length; i++) {
      const $this = areaList[i];
      $this.addEventListener("mouseover", () => {
        this.drawPoly($this.getAttribute("coords"), "rgba(171,144,96,0.5)");
      });
    }

    for (let i = 0; i < areaList.length; i++) {
      const $this = areaList[i];
      $this.addEventListener("mouseleave", () => {
        const canvas = document.querySelector("canvas");
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
      });
    }
  }

  drawPoly = (coordinates, color) => {
    const canvas = document.querySelector("canvas");
    const parent = canvas.parentNode;
    canvas.width = parent.offsetWidth;
    canvas.height = parent.offsetHeight;
    const context = canvas.getContext("2d");

    context.fillStyle = color;
    context.lineWidth = 0;
    const mCoords = coordinates.split(",");
    const n = mCoords.length;
    context.beginPath();
    context.moveTo(mCoords[0], mCoords[1]);
    for (let i = 2; i < n; i += 2) {
      context.lineTo(mCoords[i], mCoords[i + 1]);
    }
    context.lineTo(mCoords[0], mCoords[1]);
    context.fill();
  };

  render() {
    return (
      <div className={style.houseImage}>
        <canvas></canvas>

        <div className="image-map">
          <img src={houseImage} useMap="#image-map" />

          <map name="image-map">
            <area
              target=""
              alt="3_этаж"
              title="3_этаж"
              href="house/floor-plan-3"
              coords="968,2812,989,2920,960,2937,964,2974,2322,4045,2680,3941,3472,3370,3780,2929,3805,2812,3497,3179,2668,3754,2322,3854"
              shape="poly"
            />
            <area
              target=""
              alt="4_этаж"
              title="4_этаж"
              href="house/floor-plan-4"
              coords="910,2651,2339,3688,2676,3584,3543,3034,3838,2638,3797,2826,3522,3167,2672,3751,2330,3851,968,2809,955,2697"
              shape="poly"
            />
            <area
              target=""
              alt="5_этаж"
              title="5_этаж"
              href="house/floor-plan-5"
              coords="914,2487,2330,3470,2689,3383,3584,2820,3901,2399,3843,2628,3530,3037,2676,3587,2330,3683,914,2658,905,2628,935,2599"
              shape="poly"
            />
            <area
              target=""
              alt="6_этаж"
              title="6_этаж"
              href="house/floor-plan-6"
              coords="826,2278,2318,3287,2697,3187,3638,2637,3926,2274,3888,2412,3559,2841,2684,3379,2330,3470,905,2478,880,2345"
              shape="poly"
            />
            <area
              target=""
              alt="7_этаж"
              title="7_этаж"
              href="house/floor-plan-7"
              coords="826,2096,2326,3051,2705,2963,3663,2421,3972,2059,3918,2271,3630,2651,2697,3192,2330,3292,843,2284,872,2255"
              shape="poly"
            />
            <area
              target=""
              alt="8_этаж"
              title="8_этаж"
              href="house/floor-plan-8"
              coords="755,1909,2339,2822,2747,2730,3584,2267,3751,1888,3963,1959,3959,2013,3984,2034,3663,2417,2709,2959,2334,3055,830,2105,810,1959"
              shape="poly"
            />
            <area
              target=""
              alt="9_этаж"
              title="9_этаж"
              href="house/floor-plan-9"
              coords="747,1692,2334,2526,2755,2434,3247,2201,3551,1659,3780,1709,3768,1759,3755,1888,3588,2267,2739,2730,2339,2817,764,1909,780,1867"
              shape="poly"
            />
            <area
              target=""
              alt="10_этаж"
              title="10_этаж"
              href="house/floor-plan-10"
              coords="668,1442,2339,2221,2634,2167,3051,1671,3155,1692,3530,1267,3505,1442,3580,1455,3568,1563,3530,1605,3555,1659,3247,2201,2763,2434,2339,2521,751,1684,710,1480"
              shape="poly"
            />
          </map>
        </div>
      </div>
    );
  }
}

export default HousePlan;
