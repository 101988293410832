import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper.scss";
import "./Slider.css";
import PropTypes from "prop-types";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

class Slider extends React.Component {
  render() {
    const { slides } = this.props;
    return (
      <div className="slider">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation={true}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {slides &&
            slides.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className="landing-house-planing1"
                  style={{ backgroundImage: `url('${item}')` }}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    );
  }
}

Slider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Slider;
