import React from "react";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import style from "./Parking.module.scss";
import apiService from "../../services/apiService";

class Parking extends React.Component {
  state = {
    popupShow: false,
    sortingPopupIsOpen: false,
    sortingValue: {
      key: "square_desc",
      value: "БІЛЬШІЙ ПЛОЩІ",
    },
    data: {
      meta: {},
    },
    sort: [
      {
        key: "square_desc",
        value: "БІЛЬШІЙ ПЛОЩІ",
      },
      {
        key: "square_asc",
        value: "МЕНШІЙ ПЛОЩІ",
      },
    ],
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    apiService
      .parking()
      .then((resp) => {
        this.setState(
          {
            data: resp.data,
          },
          () => {
            this.sortList();
          }
        );
      })
      .catch((error) => {
        console.error("fetchParking", error);
        throw error;
      });
  };

  onPopupChange = () => {
    this.setState({ popupShow: !this.state.popupShow });
  };

  sortList = () => {
    let list = [];
    if (this.state.sortingValue.key === "square_asc") {
      list = this.state.data.parking.sort((a, b) => {
        if (+a.total_area > +b.total_area) {
          return 1;
        }
        if (+a.total_area < +b.total_area) {
          return -1;
        }
        return 0;
      });
    } else if (this.state.sortingValue.key === "square_desc") {
      list = this.state.data.parking.sort((a, b) => {
        if (+b.total_area > +a.total_area) {
          return 1;
        }
        if (+b.total_area < +a.total_area) {
          return -1;
        }
        return 0;
      });
    }
    this.setState((state) => {
      return {
        data: {
          ...state.data,
          parking: list,
        },
      };
    });
  };

  sortHandler = (item) => {
    this.setState(
      {
        sortingPopupIsOpen: false,
        sortingValue: item,
      },
      () => {
        this.sortList();
      }
    );
  };

  render() {
    const { meta, parking } = this.state.data;
    return (
      <div className={style.parking}>
        {this.state.popupShow && (
          <div className={style.parkingPopup}>
            <div className={style.popupHeader}>
              <div
                onClick={() => this.onPopupChange()}
                className={style.headerClose}
              >
                <div className={style.closeIcon} />
              </div>
            </div>
            <div className={style.popupImage} />
          </div>
        )}
        <Breadcrumbs current={this.state.data.title} />
        <div className={style.parkingHead}>
          <div className={style.parkingTitle}>{this.state.data.title}</div>
          <div className={style.parkingSort}>
            <div className={style.sortTitle}>СОРТУВАТИ ПО:</div>
            <div
              className={style.sortValue}
              onClick={() => {
                this.setState({
                  sortingPopupIsOpen: true,
                });
              }}
            >
              {this.state.sortingValue.value}
            </div>
            {this.state.sortingPopupIsOpen && (
              <div className={style.sortingPopup}>
                {this.state.sort &&
                  this.state.sort.map((item) => (
                    <div
                      key={item.key}
                      className={style.popupItem}
                      onClick={() => this.sortHandler(item)}
                    >
                      {item.value}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        <div className={style.parkingMain}>
          {parking &&
            parking.map((item) => (
              <div
                className={style.mainItem}
                onClick={() => this.onPopupChange()}
                key={item.id}
              >
                <div className={style.itemType}>{item.title}</div>
                <div className={style.itemSquer}>{item.total_area} м&#178;</div>
                {item.price && (
                  <div className={style.itemPrice}>{item.price} грн</div>
                )}
                <div className={style.itemChoose}>
                  <div className={style.chooseHide}>Обрати</div>
                  <div className={style.chooseArrow} />
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default Parking;
