import React from "react";
import { Link } from "react-router-dom";
import Gallery from "../../Component/Gallery/Gallery";
import GalleryPopup from "../../Component/GalleryPopup/GalleryPopup";
import OtherSlider from "../../Component/OtherSlider/OtherSlider";
import Slider from "../../Component/Slider/Slider";
import Subscribe from "../../Component/Subscribe/Subscribe";
import { ReactComponent as Arrow } from "../../Images/downArrowLanding.svg";
import style from "./NewLanding.module.scss";
import apiService from "../../services/apiService";
import { LiteralsContext } from "../../context";
import Meta from "../../Component/Meta/Meta";

class NewLanding extends React.Component {
  state = {
    popupIsOpen: false,
    imageId: 0,
    data: {
      meta: {},
      media: {},
      flat_type_widget: [],
    },
    anotherProjects: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    apiService
      .home()
      .then((resp) => {
        this.setState({
          data: resp.data,
        });
      })
      .catch((error) => {
        console.error("fetchHome", error);
        throw error;
      });

    apiService
      .anotherProjects()
      .then((resp) => {
        this.setState({
          anotherProjects: resp.data,
        });
      })
      .catch((error) => {
        console.error("anotherProjects", error);
        throw error;
      });
  };

  popupOpen = (id) => {
    this.setState({
      popupIsOpen: true,
      imageId: id,
    });
    document.body.style.overflow = "hidden";
  };

  onPopupChange = () => {
    this.setState({
      popupIsOpen: !this.state.popupIsOpen,
    });
    document.body.style.overflow = "visible";
  };

  render() {
    const { anotherProjects } = this.state;
    const { meta, media, flat_type_widget } = this.state.data;
    const { social_links } = this.context;

    return (
      <div className={style.landing}>
        <Meta title={meta.first_block_title} image={media.main_page_banner} />

        {this.state.popupIsOpen && (
          <GalleryPopup
            imageType={this.state.imageId}
            type="popup"
            handle={this.onPopupChange}
          />
        )}
        <div className="landing_intro">
          <div className="landing_intro-top">
            <div className="socialIcons_wrapper">
              {social_links.facebook && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={social_links.facebook.content}
                  className="social-facebook"
                />
              )}
              {social_links.instagram && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={social_links.instagram.content}
                  className="social-instagram"
                />
              )}
              {social_links.telegram && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={social_links.telegram.content}
                  className="social-telegram"
                />
              )}
              {social_links.youtube && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={social_links.youtube.content}
                  className="social-youtube"
                />
              )}
            </div>
            <div className="landing_intro-topLeft">
              <div className="landing_intro-title">
                {meta.first_block_title}
              </div>
              <Link to="/house" className="landing-house-button">
                {meta.first_block_btn_title}
              </Link>
              <div className="landing-intro-goDownButton">
                <Arrow />
              </div>
              <div className="landing-intro-houseType">
                {flat_type_widget.length &&
                  flat_type_widget.map((item) => (
                    <Link
                      to="/house"
                      className="landing_intro-houseTypeItem"
                      key={item.id}
                    >
                      <div className="landing_intro-houseTypeTop">
                        <div className="houseTypeTop-size">{item.title}</div>
                        <div className="houseTypeTop-text">квартири</div>
                      </div>
                      <div className="landing_intro-houseTypeBot">
                        <div className="land_help_width">
                          <div className="houseTypeBot-title">Площа</div>
                          <div className="houseTypeBot-value">
                            від {item.flat_widget.total_area_from} м&#178;
                          </div>
                        </div>
                        <div className="price">
                          <div className="houseTypeBot-title">
                            Ціна за м&#178;
                          </div>
                          <div className="houseTypeBot-value">
                            від {item.flat_widget.price_square_from} грн
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
            <div
              className="landing-intro-topImage"
              style={{ backgroundImage: `url('${media.main_page_banner}')` }}
            />
            <div className="secondBlock-absolute">
              <div className="landing_intro-title">
                {meta.first_block_title}
              </div>
              <Link to="/house" className="landing-house-button">
                {meta.first_block_btn_title}
              </Link>
            </div>
            <div className={style.goDownButton}>
              <Arrow />
            </div>
          </div>
          <div className="landing_intro-bottom">
            <div className={style.help}>
              <div className="landing_house-line" />
              <div className="landing_house-titleText">
                {meta.second_block_section_title}
              </div>
              <div className="landing_house-line" />
            </div>
            <div
              className="landing_intro-botImage"
              style={{
                backgroundImage: `url('${media.main_page_second_block}')`,
              }}
            />
            <div className="landing_intro-right">
              <div className="landing_intro-BotTitle">
                <div className="landing_house-line" />
                <div className="landing_house-titleText">
                  {meta.second_block_section_title}
                </div>
              </div>
              <div className="landing_intro-subtitle">
                {meta.second_block_title}
              </div>
              <div
                className="landing_intro-text"
                dangerouslySetInnerHTML={{
                  __html: meta.second_block_content,
                }}
              />
              <Link to="/about-the-complex" className="landing-itro-button">
                {meta.second_block_btn_title}
              </Link>
            </div>
          </div>
        </div>
        <div className={style.landing_secondBlock}>
          <div className={style.secondTop}>
            <div className={style.topLeft}>
              <div className={style.infoWrapper}>
                <div className={style.blockName}>
                  <div className={style.nameLine} />
                  <div className={style.nameText}>
                    {meta.third_block_section_title}
                  </div>
                </div>
                <div className={style.blockTitle}>{meta.third_block_title}</div>
                <div
                  className={style.blockText}
                  dangerouslySetInnerHTML={{
                    __html: meta.third_block_content,
                  }}
                ></div>
                <Link to="/house" className="landing-house-button">
                  {meta.third_block_btn_title}
                </Link>
              </div>
            </div>
            <div className="landing-house-wrapper">
              <div className="landing-house-buttonWrapper">
                <div className="landing-house-ArrowButton">&#8594;</div>
                <div className="landing-house-ArrowButton">&#8592;</div>
              </div>
              {media.main_page_third_block && (
                <Slider slides={media.main_page_third_block} />
              )}
            </div>
            <div className={style.help}>
              <div className="landing_house-line" />
              <div className="landing_house-titleText">
                {meta.third_block_section_title}
              </div>
              <div className="landing_house-line" />
            </div>
          </div>
          <div className={style.secondBottom}>
            <div className={style.help}>
              <div className="landing_house-line" />
              <div className="landing_house-titleText">
                {meta.fourth_block_section_title}
              </div>
              <div className="landing_house-line" />
            </div>
            <div
              className={style.secondBottomImage}
              style={{
                backgroundImage: `url('${media.main_page_fourth_block}')`,
              }}
            />
            <div className={style.infoWrapper}>
              <div className={style.blockName}>
                <div className={style.nameLine} />
                <div className={style.nameText}>
                  {meta.fourth_block_section_title}
                </div>
              </div>
              <div className={style.blockTitle}>{meta.fourth_block_title}</div>
              <div
                className={style.blockText}
                dangerouslySetInnerHTML={{
                  __html: meta.fourth_block_content,
                }}
              ></div>
              <Link to="/about-developer" className="landing-house-botButton">
                {meta.fourth_block_btn_title}
              </Link>
            </div>
          </div>
          <div className={style.thirdBlock}>
            <div className={style.infoWrapper}>
              <div className={style.blockName}>
                <div className={style.nameLine} />
                <div className={style.nameText}>
                  {meta.fifth_block_section_title}
                </div>
              </div>
              <div className={style.blockTitle}>{meta.fifth_block_title}</div>
              <Link
                to="/construction-progress"
                className="landing_constractionStatus-button"
              >
                {meta.fifth_block_btn_title}
              </Link>
            </div>
            <div
              className={style.thirdImage}
              style={{
                backgroundImage: `url('${media.main_page_fifth_block}')`,
              }}
            />
            <div className={style.help}>
              <div className="landing_house-line" />
              <div className="landing_house-titleText">
                {meta.fifth_block_section_title}
              </div>
              <div className="landing_house-line" />
            </div>
          </div>
          <div className={style.fourthBlock}>
            <div className={style.fourthTop}>
              <div className={style.help}>
                <div className="landing_house-line" />
                <div className="landing_house-titleText">
                  {meta.six_block_section_title}
                </div>
                <div className="landing_house-line" />
              </div>
              <div
                className={style.fourthImage}
                style={{
                  backgroundImage: `url('${media.main_page_six_block}')`,
                }}
              />
              <div className={style.infoWrapper}>
                <div className={style.blockName}>
                  <div className={style.nameLine} />
                  <div className={style.nameText}>
                    {meta.six_block_section_title}
                  </div>
                </div>
                <div className={style.blockTitle}>{meta.six_block_title}</div>
                <div
                  className={style.blockText}
                  dangerouslySetInnerHTML={{
                    __html: meta.six_block_content,
                  }}
                ></div>
                <Link
                  to="/infrastructure"
                  className="landing-infrastructure-button"
                >
                  {meta.six_block_btn_title}
                </Link>
              </div>
            </div>
            <div className="landing_gallery">
              <div className="landing_gallery-header">
                <div className="landing_gallery-title">Галерея</div>
                <Link to="/gallery" className="landing_gallery-wrapper">
                  <div className="landing_gallery-all">
                    Дивитись усі рендери
                  </div>
                  <div className="landing_gallery-arrowIcon" />
                </Link>
              </div>
              <div>
                <div className="landing-gallery-imagesWrapper">
                  <div className="landing-gallery-nav">
                    <div className="gallery_nav-arrow" />
                    <div className="gallery_nav-arrowRotate" />
                  </div>
                </div>
                <div>
                  {media.main_page_gallery && (
                    <Gallery
                      callback={this.popupOpen}
                      slides={media.main_page_gallery}
                    />
                  )}
                </div>
                <div>
                  <div className="landing-gallery-progressLine">
                    <div className="landing-gallery-currentProgress" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Subscribe />
          <div className="landing_other">
            <div className="landing-other-left">
              <div className={style.help}>
                <div className="landing_house-line" />
                <div className="landing_house-titleText">ПРОЕКТИ</div>
                <div className="landing_house-line" />
              </div>
              <div className="landing_other-title">
                <div className="landing_other-line" />
                <div className="landing_other-titleText">ПРОЕКТИ</div>
              </div>
              <div className="landing_other-subtitle">
                Інші проекти
                <br />
                від забудовника
                <br />
                &#171;Креатор-Буд&#187;
              </div>
              <div className="landing_other-logo" />
            </div>
            <div className="landing-other-right">
              <OtherSlider projects={anotherProjects} />
              <div className={style.otherWrapper}>
                {anotherProjects &&
                  anotherProjects.map((item) => (
                    <a
                      key={item.id}
                      href={item.site_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="landing-other-projectBlock"
                    >
                      <div
                        className="landing-other-imageFirst"
                        style={{ backgroundImage: `url('${item.image}')` }}
                      />
                      <div className="landing-other-imageText">
                        {item.title}
                      </div>
                    </a>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NewLanding.contextType = LiteralsContext;

export default NewLanding;
