import React from "react";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import GoogleMapReact from "google-map-react";
import style from "./Infrastructure.module.scss";
import apiService from "../../services/apiService";
import { ReactComponent as Marker } from "../../Images/Vector.svg";
import Meta from "../../Component/Meta/Meta";

const MarkersC = ({ text }) => <Marker className={style.markerIcon} />;

class Infrastructure extends React.Component {
  state = {
    isGoogleMapsLoad: false,
    data: {
      meta: {},
      media: {},
    },
  };

  static defaultProps = {
    center: {
      lat: 50.443735155804184,
      lng: 30.52253039811166,
    },
    zoom: 17,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    apiService
      .infrastructure()
      .then((resp) => {
        this.setState({
          data: resp.data,
        });
      })
      .catch((error) => {
        console.error("fetchInfrastructure", error);
        throw error;
      });
  };

  onMapChange = () => {
    this.setState({ isGoogleMapsLoad: true });
  };

  render() {
    const { meta, media } = this.state.data;
    return (
      <>
        <Meta title={this.state.data.title} image={media.infrastructure_banner} />

        <div className={style.infrastructure}>
          <Breadcrumbs current={this.state.data.title} />
          <div
            className={style.infrastructureImage}
            style={{ backgroundImage: `url('${media.infrastructure_banner}')` }}
          />
          <div className={style.infrastructureMainText}>
            <div className={style.infrastructureTitle}>
              {meta.first_block_title}
            </div>
            <div
              className={style.infrastructureText}
              dangerouslySetInnerHTML={{
                __html: meta.first_block_content,
              }}
            ></div>
          </div>
        </div>
        <div className={style.infrastructureFirst}>
          <div className={style.firstTop}>
            <div
              className={style.topImage}
              style={{
                backgroundImage: `url('${media.infrastructure_second_block}')`,
              }}
            />
            <div className={style.toptextWrapper}>
              <div className={style.wrapperTitle}>
                {meta.second_block_title}
              </div>
              <div
                className={style.wrapperText}
                dangerouslySetInnerHTML={{
                  __html: meta.second_block_content,
                }}
              ></div>
            </div>
          </div>
          <div className={style.firstMiddle}>
            <div className={style.toptextWrapper}>
              <div className={style.wrapperTitle}>{meta.third_block_title}</div>
              <div
                className={style.wrapperText}
                dangerouslySetInnerHTML={{
                  __html: meta.third_block_content,
                }}
              ></div>
            </div>
            <div
              className={style.middleImage}
              style={{
                backgroundImage: `url('${media.infrastructure_third_block}')`,
              }}
            />
          </div>
          <div className={style.firstBottom}>
            <div
              className={style.botImage}
              style={{
                backgroundImage: `url('${media.infrastructure_fourth_block}')`,
              }}
            />
            <div className={style.toptextWrapper}>
              <div className={style.wrapperTitle}>
                {meta.fourth_block_title}
              </div>
              <div
                className={style.wrapperText}
                dangerouslySetInnerHTML={{
                  __html: meta.fourth_block_content,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className={style.infrastructureSecond}>
          <div className={style.secondWrapper}>
            <div className={style.planTitle}>{meta.fifth_block_title}</div>
            <div
              className={style.planTextWrapper}
              dangerouslySetInnerHTML={{
                __html: meta.fifth_block_content,
              }}
            ></div>
          </div>
          <div
            className={style.bottonImage}
            style={{
              backgroundImage: `url('${media.infrastructure_fifth_block}')`,
            }}
          />
        </div>
        <div className={style.maps}>
          {this.state.isGoogleMapsLoad ? (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyA0iOxpzr8dGr-6kZ5p-daTSNPub-6Tpt4",
              }}
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
            >
              <MarkersC
                lat={50.443893155904184}
                lng={30.52234039811166}
                text="hello"
              />
            </GoogleMapReact>
          ) : (
            <div
              className={style.noMapsImage}
              style={{
                backgroundImage: `url('${media.infrastructure_image_map}')`,
              }}
              onClick={() => this.onMapChange()}
            />
          )}
        </div>
      </>
    );
  }
}

export default Infrastructure;
