import React from "react";
import { Link } from "react-router-dom";
import style from "./Documents.module.scss";

import docImage from "../../Images/infrastructureLanding.jpg";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import apiService from "../../services/apiService";

class Documents extends React.Component {
  state = {
    data: {
      meta: {},
      media: {},
      documents: [],
    },
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    apiService
      .documents()
      .then((resp) => {
        this.setState({
          data: resp.data,
        });
      })
      .catch((error) => {
        console.error("fetchAbout", error);
        throw error;
      });
  };

  render() {
    return (
      <div className={style.documents}>
        <Breadcrumbs current={this.state.data.title} />
        <div
          className={style.docImage}
          style={{
            backgroundImage: `url('${this.state.data.media.document_banner}')`,
          }}
        />
        <div className={style.infoBlock}>
          <div className={style.title}>{this.state.data.title}</div>
          <div className={style.docs}>
            {this.state.data.documents &&
              this.state.data.documents.map((item) => (
                <a
                  target="_blank"
                  href={item.file}
                  className={style.docItem}
                  key={item.id}
                >
                  <div className={style.itemWrapper}>
                    <div className={style.docIcon} />
                    <div className={style.docText}>{item.title}</div>
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Documents;
