import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import cn from "classnames";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper.scss";
import "./Gallery.css";
import PropTypes from "prop-types";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
class Gallery extends React.Component {
  render() {
    const isMobile = window.screen.width >= 320 && window.screen.width <= 425;
    let getSlides = 0;
    if (window.screen.width === 320) {
      getSlides = 1.65;
    }
    if (window.screen.width === 375) {
      getSlides = 1.9;
    }
    if (window.screen.width === 425) {
      getSlides = 2.2;
    }
    console.log({ getSlides });
    const { slides } = this.props;
    return (
      <div
        className={cn("gallery", { galleryPopup: this.props.type === "popup" })}
      >
        <Swiper
          breakpoints={{
            // when window width is >= 640px
            320:{
              slidesPerView: 1.5
            },
            375: {
              slidesPerView: 1.8,
            },
            // when window width is >= 768px
            425: {
              slidesPerView: 2.2,
            },
          }}
          spaceBetween={isMobile ? 10 : 50}
          slidesPerView={2.2}
          navigation={true}
          zoom
          scrollbar={{ draggable: true }}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {slides &&
            slides.map((item, index) => (
              <SwiperSlide
                onClick={() => this.props.callback(item)}
                key={index}
              >
                <img src={item} alt="item" />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    );
  }
}

Gallery.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Gallery;
