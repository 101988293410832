import React from "react";
import { Slider } from "@material-ui/core";
import PropTypes from "prop-types";
import style from "./../../Container/CommercialProperty/CommercialProperty.module.scss";

class RangeSlider extends React.Component {
  onChangeHandler = (e, data) => {
    this.props.onChange({
      key: this.props.data.key,
      selected: {
        from: data[0],
        to: data[1],
      },
    });
  };

  render() {
    const { label, values, selected, key } = this.props.data;
    return (
      <div className={style.filterSquere}>
        <div
          className={style.squereTitle}
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        ></div>
        <Slider
          onChange={this.onChangeHandler}
          min={values.from}
          max={values.to}
          defaultValue={[selected.from, selected.to]}
          step={key === "total_area" ? 0.1 : 1}
          value={[selected.from, selected.to]}
        />
        <div className={style.squereInfo}>
          <div className={style.Min}>{selected.from}</div>
          <div className={style.Max}>{selected.to}</div>
        </div>
      </div>
    );
  }
}

RangeSlider.propTypes = {
  data: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    values: PropTypes.object,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RangeSlider;
