import React from "react";
import MultiplyCheckbox from "../../Component/Filters/MultiplyCheckbox";
import RangeSlider from "../../Component/Filters/RangeSlider";
import SingleCheckbox from "../../Component/Filters/SingleCheckbox";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import "rc-slider/assets/index.css";
import style from "./CommercialProperty.module.scss";
import { Link } from "react-router-dom";
import apiService from "../../services/apiService";
import Meta from "../../Component/Meta/Meta";

class CommercialProperty extends React.Component {
  state = {
    sortingPopupIsOpen: false,
    sortingValue: {
      key: "square_desc",
      value: "БІЛЬШІЙ ПЛОЩІ",
    },
    apiType: this.props.match.path === "/layout" ? `flats` : `commercial_flats`,
    isFetching: false,
    flats: [],
    filters: null,
    sort: [
      {
        key: "square_desc",
        value: "БІЛЬШІЙ ПЛОЩІ",
      },
      {
        key: "square_asc",
        value: "МЕНШІЙ ПЛОЩІ",
      },
    ],
  };

  componentDidMount() {
    this.fetchFilters().then(() => this.fetchData());
  }

  fetchFilters = () => {
    this.setState({ isFetching: true });
    return apiService
      .flatsFilters(this.state.apiType)
      .then((resp) => {
        this.setState({
          filters: resp.data.filters.map((item) => {
            if (item.type === "multiple") {
              const { values } = item;
              const mappedList = Object.keys(values).map((item) => {
                return {
                  key: item,
                  value: values[item],
                  isChecked: false,
                };
              });
              return {
                ...item,
                mappedList,
              };
            } else if (item.type === "slider") {
              const { values } = item;
              return {
                ...item,
                values: {
                  from: Number(values.from),
                  to: Number(values.to),
                },
                selected: {
                  from: Number(values.from),
                  to: Number(values.to),
                },
              };
            }
            return { ...item };
          }),
        });
      })
      .catch((error) => {
        console.error("fetchFlatsFilters", error);
        throw error;
      });
  };

  fetchData = () => {
    let data = {};
    this.state.filters.forEach((item) => {
      if (item.type === "multiple") {
        data[item.key] = item.mappedList
          .reduce((accum, z) => {
            if (z.isChecked) {
              return accum.concat(z.key);
            }
            return accum;
          }, [])
          .join(",");
      } else if (item.type === "slider") {
        data[item.key] = `${item.selected.from}-${item.selected.to}`;
      } else if (item.type === "boolean") {
        data[item.key] = !!item.checked;
      }
    });
    this.setState({ isFetching: true });
    apiService
      .flats(data, this.state.apiType)
      .then((resp) => {
        this.setState(
          {
            flats: resp.data,
          },
          () => {
            this.sortList();
          }
        );
        this.setState({ isFetching: false });
      })
      .catch((error) => {
        this.setState({ isFetching: false });
        console.error("fetchFlats", error);
        throw error;
      });
  };

  onMultipleChange = (elem) => {
    this.setState((state) => ({
      filters: state.filters.map((item) => {
        if (item.key === elem.key) {
          return {
            ...item,
            mappedList: item.mappedList.map((m) => {
              if (m.key === elem.item.key) {
                return { ...m, isChecked: !m.isChecked };
              }
              return m;
            }),
          };
        }
        return item;
      }),
    }));
  };

  onRangeChange = (elem) => {
    this.setState((state) => ({
      filters: state.filters.map((item) => {
        if (item.key === elem.key) {
          return {
            ...item,
            selected: { ...elem.selected },
          };
        }
        return item;
      }),
    }));
  };

  onSingleChange = (elem) => {
    this.setState((state) => ({
      filters: state.filters.map((item) => {
        if (item.key === elem.key) {
          return { ...item, checked: !item.checked };
        }
        return item;
      }),
    }));
  };

  sortList = () => {
    let list = [];
    if (this.state.sortingValue.key === "square_asc") {
      list = this.state.flats.sort((a, b) => {
        if (+a.total_area > +b.total_area) {
          return 1;
        }
        if (+a.total_area < +b.total_area) {
          return -1;
        }
        return 0;
      });
    } else if (this.state.sortingValue.key === "square_desc") {
      list = this.state.flats.sort((a, b) => {
        if (+b.total_area > +a.total_area) {
          return 1;
        }
        if (+b.total_area < +a.total_area) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({
      flats: list,
    });
  };

  sortHandler = (item) => {
    this.setState(
      {
        sortingPopupIsOpen: false,
        sortingValue: item,
      },
      () => {
        this.sortList();
      }
    );
  };

  renderApartaments = () => {
    if (this.state.isFetching) {
      return <p className={style.notFound}>Завантаження...</p>;
    }
    if (!this.state.flats || !this.state.flats.length) {
      return (
        <p className={style.notFound}>За вашим запитом нічого не знайдено</p>
      );
    }
    return this.state.flats.map((item) => {
      return (
        <Link
          to={
            this.props.match.path === "/layout"
              ? `/layout/${item.id}`
              : `/commercial-property/${item.id}`
          }
          className={style.item}
          key={item.id}
        >
          <div className={style.itemTop}>
            <div
              className={style.itemImage}
              style={{ backgroundImage: `url('${item.flat_plan.tech_plan}')` }}
            />
            <div className={style.itemType}>{item.flat_plan.title}</div>
          </div>
          <div className={style.itemBottom}>
            <div className={style.itemWrapper}>
              <div className={style.botTitle}>Загальна площа</div>
              <div className={style.itemLine} />
              <div className={style.botValue}>{item.total_area} м&#178;</div>
            </div>
            {/* {item.price && (
              <div className={style.itemWrapper}>
                <div className={style.botTitle}>Ціна</div>
                <div className={style.itemLine} />
                <div className={style.botValue}>від {item.price}</div>
              </div>
            )} */}
          </div>
        </Link>
      );
    });
  };

  render() {
    const { filters } = this.state;

    return (
      <div className={style.commercialProperty}>
        <Meta
          title={
            this.props.match.path === "/layout"
              ? "ПЛАНУВАННЯ КВАРТИР"
              : "КОМЕРЦІЙНА НЕРУХОМІСТЬ"
          }
        />

        <Breadcrumbs
          current={
            this.props.match.path === "/layout"
              ? "ПЛАНУВАННЯ КВАРТИР"
              : "КОМЕРЦІЙНА НЕРУХОМІСТЬ"
          }
        />
        <div className={style.top}>
          <div className={style.topTitle}>
            {this.props.match.path === "/layout"
              ? "ПЛАНУВАННЯ КВАРТИР"
              : "КОМЕРЦІЙНА НЕРУХОМІСТЬ"}
          </div>
          <div className={style.topFilter}>
            <div className={style.filterTitle}>СОРТУВАТИ ПО:</div>
            <div
              className={style.filterValue}
              onClick={() => {
                this.setState({
                  sortingPopupIsOpen: true,
                });
              }}
            >
              {this.state.sortingValue.value}
            </div>
            {this.state.sortingPopupIsOpen && (
              <div className={style.sortingPopup}>
                {this.state.sort &&
                  this.state.sort.map((item) => (
                    <div
                      key={item.key}
                      className={style.popupItem}
                      onClick={() => this.sortHandler(item)}
                    >
                      {item.value}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        <div className={style.bottom}>
          <div className={style.bottomFilter}>
            {filters &&
              filters.map((item) => {
                if (item.type === "multiple") {
                  return (
                    <MultiplyCheckbox
                      key={item.key}
                      data={item}
                      onChange={this.onMultipleChange}
                    />
                  );
                }
                if (item.type === "slider") {
                  return (
                    <RangeSlider
                      key={item.key}
                      data={item}
                      onChange={this.onRangeChange}
                    />
                  );
                }
                if (item.type === "boolean") {
                  return (
                    <SingleCheckbox
                      key={item.key}
                      data={item}
                      className={style.commercialCheckboxItem}
                      onChange={this.onSingleChange}
                    />
                  );
                }
                return "";
              })}
            <div className={style.filterAccept} onClick={this.fetchData}>
              Показати
            </div>
          </div>
          <div className={style.bottomData}>{this.renderApartaments()}</div>
        </div>
      </div>
    );
  }
}

export default CommercialProperty;
