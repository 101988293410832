import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "./Feedback.module.scss";
import InputMask from "react-input-mask";
import apiService from "../../services/apiService";

const requiredValidator = (value) => {
  return !/\S+@\S+\.\S+/.test(value);
};

const emailValidator = (value) => {
  return !value.match(/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/);
};

const phoneValidator = (value) => {
  return value.toString().trim().replace(/\s/g, "").length !== 13;
};

class Feedback extends React.Component {
  state = {
    form: {
      name: "",
      phone: "",
      email: "",
      message: "",
    },
    isDirty: false,
    submitStatus: null,
  };

  handleChange = (event, key) => {
    this.setState((state) => {
      let form = state.form;
      form[key] = event.target.value;
      return {
        form: { ...form },
      };
    });
  };

  validation = () => {
    const { name, phone, email } = this.state.form;
    return {
      name: name.trim().length < 3,
      email: requiredValidator(email) && emailValidator(email),
      phone: requiredValidator(phone) && phoneValidator(phone),
    };
  };

  anyError = () => {
    return (
      this.validation().name ||
      this.validation().phone ||
      this.validation().email
    );
  };

  clearForm() {
    this.setState({
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
    });
  }

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ isDirty: true });
    if (this.anyError()) return;

    apiService
      .feedback({
        ...this.state.form,
      })
      .then((resp) => {
        this.clearForm();
        this.setState({
          isDirty: false,
          submitStatus: "success",
        });
      })
      .catch((e) => {
        this.setState({
          isDirty: false,
          submitStatus: "error",
        });
        console.error(e);
      });

    toast.dark("Заявка прийнята!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  render() {
    const { submitStatus, isDirty } = this.state;
    const { name, phone, email, message } = this.state.form;

    const showError = {
      email: this.validation().email && isDirty,
      name: this.validation().name && isDirty,
      phone: this.validation().phone && isDirty,
    };
    
    return (
      <div className={style.apartamentPopup}>
        <div className={style.popupForm}>
          <div className={style.popupCrossWrapper}>
            <div
              className={style.popupCross}
              onClick={() => {
                this.props.handle();
              }}
            />
          </div>
          <div className={style.popupArrowWrapper}>
            <div
              className={style.popupArrow}
              onClick={() => {
                this.props.handle();
              }}
            />
          </div>
          <form className={style.feedbackRight} onSubmit={this.submitHandler}>
            <div className={style.feedbackTitle}>Залиште заявку</div>
            <div className={style.rightInputs}>
              {showError.name && (
                <div className={style.formError}>Обов'язкове поле</div>
              )}
              <input
                type="text"
                placeholder="Ім'я"
                className={style.inputName}
                value={name}
                onChange={(e) => this.handleChange(e, "name")}
              />

              <div className={style.rightShortInputs}>
                <div className={style.rightShortInputsCol}>
                  {showError.phone && (
                    <div className={style.formError}>Обов'язкове поле</div>
                  )}
                  <InputMask
                    className={style.inputPhone}
                    placeholder="Телефон"
                    mask="+38 099 999 99 99"
                    maskChar=" "
                    value={phone}
                    onChange={(e) => this.handleChange(e, "phone")}
                  />
                </div>
                <div className={style.rightShortInputsCol}>
                  {showError.email && (
                    <div className={style.formError}>Обов'язкове поле</div>
                  )}
                  <input
                    type="email"
                    placeholder="Email"
                    className={style.inputEmail}
                    value={email}
                    onChange={(e) => this.handleChange(e, "email")}
                  />
                </div>
              </div>
              <textarea
                placeholder="Повідомлення"
                className={style.rightMessage}
                value={message}
                onChange={(e) => this.handleChange(e, "message")}
              />
            </div>
            <button className={style.rightButton}>Відправити</button>

            {submitStatus === "success" && (
              <p className={style.submitMessage}>
                Заявка успішно відправлена, дякуємо!
              </p>
            )}
            {submitStatus === "error" && (
              <p className={style.submitMessage}>
                Виникли проблеми, спробуйте пізніше!
              </p>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default Feedback;
