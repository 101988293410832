import React from "react";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import GoogleMapReact from "google-map-react";
import apiService from "../../services/apiService";
import InputMask from "react-input-mask";

import style from "./Contacts.module.scss";
import Feedback from "../../Component/Feedback/Feedback";
import { LiteralsContext } from "../../context";
import { ReactComponent as Marker } from "../../Images/Vector.svg";
import Meta from "../../Component/Meta/Meta";

const MarkersC = ({ text }) => <Marker className={style.markerIcon} />;

const requiredValidator = (value) => {
  return !/\S+@\S+\.\S+/.test(value);
};

const emailValidator = (value) => {
  return !value.match(/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/);
};

const phoneValidator = (value) => {
  return value.toString().trim().replace(/\s/g, "").length !== 13;
};

class Contacts extends React.Component {
  state = {
    isGoogleMapsLoad: false,
    popupIsOpen: false,
    data: {
      meta: {},
      contacts_image_map: "",
    },
    form: {
      name: "",
      phone: "",
      email: "",
      message: "",
    },
    isDirty: false,
    submitStatus: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    apiService
      .contacts()
      .then((resp) => {
        this.setState({
          data: resp.data,
        });
      })
      .catch((error) => {
        console.error("fetchContacts", error);
        throw error;
      });
  };

  handleChange = (event, key) => {
    this.setState((state) => {
      let form = state.form;
      form[key] = event.target.value;
      return {
        form: { ...form },
      };
    });
  };

  validation = () => {
    const { name, phone, email } = this.state.form;
    return {
      name: name.trim().length < 3,
      email: requiredValidator(email) && emailValidator(email),
      phone: requiredValidator(phone) && phoneValidator(phone),
    };
  };

  anyError = () => {
    return (
      this.validation().name ||
      this.validation().phone ||
      this.validation().email
    );
  };

  clearForm() {
    this.setState({
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
    });
  }

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ isDirty: true });
    if (this.anyError()) return;

    apiService
      .feedback({
        ...this.state.form,
      })
      .then((resp) => {
        this.clearForm();
        this.setState({
          isDirty: false,
          submitStatus: "success",
        });
      })
      .catch((e) => {
        this.setState({
          isDirty: false,
          submitStatus: "error",
        });
        console.error(e);
      });
  };

  popupHandle = () => {
    this.setState({
      popupIsOpen: false,
    });
    document.body.style.overflow = "visible";
  };

  popupOpen = () => {
    const isMobile = window.screen.width <= 1024;
    this.setState(
      {
        popupIsOpen: true,
      },
      () => {
        document.body.style.overflow = "hidden";
      }
    );
  };
  static defaultProps = {
    center: {
      lat: 50.443735155804184,
      lng: 30.52253039811166,
    },
    zoom: 17,
  };

  onMapChange = () => {
    this.setState({ isGoogleMapsLoad: true });
  };

  render() {
    const { meta, contacts_image_map } = this.state.data;
    const { contact_info, social_links } = this.context;
    const { submitStatus, isDirty } = this.state;
    const { name, phone, email, message } = this.state.form;
    const showError = {
      email: this.validation().email && isDirty,
      name: this.validation().name && isDirty,
      phone: this.validation().phone && isDirty,
    };

    return (
      <>
        <div className={style.contacts}>
          <Meta title={this.state.data.title} />

          {this.state.popupIsOpen && <Feedback handle={this.popupHandle} />}
          <Breadcrumbs current={this.state.data.title} />
          <div className={style.contactsHead}>{this.state.data.title}</div>
          <div className={style.contactsInfo}>
            <div className={style.infoLeft}>
              <div className={style.infoTitle}>Офіс продажу:</div>
              <div className={style.infoLine} />
              {contact_info.contact_page_sales_office_address && (
                <div
                  className={style.infoAddress}
                  dangerouslySetInnerHTML={{
                    __html:
                      contact_info.contact_page_sales_office_address.content,
                  }}
                />
              )}
            </div>
            <div className={style.infoRight}>
              <div className={style.infoTitle}>Зв'язок з нами:</div>
              <div className={style.infoLine} />
              <div className={style.infoEmailPhone}>
                {contact_info.email && (
                  <a href={`mailto:${contact_info.email.content}`}>
                    {contact_info.email.content}
                  </a>
                )}
                <br />
                {contact_info.phone_label && (
                  <a href={`tel:${contact_info.phone_link_value.content}`}>
                    {contact_info.phone_label.content}
                  </a>
                )}
              </div>
              <div
                className={style.infoCallButton}
                onClick={() => this.popupOpen()}
              >
                Замовити дзвінок
              </div>
              <div className={style.infoSocial}>
                {social_links.facebook && (
                  <a
                    target="_blank"
                    href={social_links.facebook.content}
                    className={style.socialFacebook}
                  >
                    <div className={style.facebookIcon} />
                  </a>
                )}
                {social_links.instagram && (
                  <a
                    target="_blank"
                    href={social_links.instagram.content}
                    className={style.socialInstagram}
                  >
                    <div className={style.instagramIcon} />
                  </a>
                )}
                {social_links.telegram && (
                  <a
                    target="_blank"
                    href={social_links.telegram.content}
                    className={style.socialTelegram}
                  >
                    <div className={style.telegramIcon} />
                  </a>
                )}
                {social_links.youtube && (
                  <a
                    target="_blank"
                    href={social_links.youtube.content}
                    className={style.socialYoutube}
                  >
                    <div className={style.youtubeIcon} />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={style.maps}>
          {this.state.isGoogleMapsLoad ? (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyA0iOxpzr8dGr-6kZ5p-daTSNPub-6Tpt4",
              }}
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
            >
              <MarkersC
                lat={50.443893155904184}
                lng={30.52234039811166}
                text="hello"
              />
            </GoogleMapReact>
          ) : (
            <div
              className={style.noMapsImage}
              style={{
                backgroundImage: `url('${contacts_image_map}')`,
              }}
              onClick={() => this.onMapChange()}
            />
          )}
        </div>
        <div className={style.advice}>
          <div className={style.adviceLeft}>
            <div className={style.leftTitle}>{meta.form_block_title}</div>
            <div
              className={style.leftText}
              dangerouslySetInnerHTML={{
                __html: meta.form_block_content,
              }}
            ></div>
          </div>
          <form className={style.adviceRight} onSubmit={this.submitHandler}>
            <div className={style.rightInputs}>
              <div>
                {showError.name && (
                  <div className={style.formError}>Обов'язкове поле</div>
                )}
                <input
                  type="text"
                  placeholder="Ім'я"
                  className={style.inputName}
                  value={name}
                  onChange={(e) => this.handleChange(e, "name")}
                />
              </div>
              <div className={style.rightShortInputs}>
                <div>
                  {showError.phone && (
                    <div className={style.formError}>Обов'язкове поле</div>
                  )}
                  <InputMask
                    className={style.inputPhone}
                    placeholder="Телефон"
                    mask="+38 099 999 99 99"
                    maskChar=" "
                    value={phone}
                    onChange={(e) => this.handleChange(e, "phone")}
                  />
                </div>
                <div>
                  {showError.email && (
                    <div className={style.formError}>Обов'язкове поле</div>
                  )}
                  <input
                    type="email"
                    placeholder="Email"
                    className={style.inputEmail}
                    value={email}
                    onChange={(e) => this.handleChange(e, "email")}
                  />
                </div>
              </div>
              <textarea
                placeholder="Повідомлення"
                className={style.rightMessage}
                value={message}
                onChange={(e) => this.handleChange(e, "message")}
              />
            </div>
            <button type="submit" className={style.rightButton}>
              Відправити
            </button>
            {submitStatus === "success" && (
              <p className={style.submitMessage}>
                Заявка успішно відправлена, дякуємо!
              </p>
            )}
            {submitStatus === "error" && (
              <p className={style.submitMessage}>
                Виникли проблеми, спробуйте пізніше!
              </p>
            )}
          </form>
        </div>
      </>
    );
  }
}
Contacts.contextType = LiteralsContext;

export default Contacts;
