import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Subscribe.css";
import apiService from "../../services/apiService";

const requiredValidator = (value) => {
  return !/\S+@\S+\.\S+/.test(value);
};

const emailValidator = (value) => {
  return !value.match(/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/);
};

class Subscribe extends React.Component {
  state = {
    form: {
      email: "",
    },
    isDirty: false,
    submitStatus: null,
  };

  handleChange = (event, key) => {
    this.setState((state) => {
      let form = state.form;
      form[key] = event.target.value;
      return {
        form: { ...form },
      };
    });
  };

  validation = () => {
    const { email } = this.state.form;
    return {
      email: requiredValidator(email) && emailValidator(email),
    };
  };

  anyError = () => {
    return this.validation().email;
  };

  clearForm() {
    this.setState({
      form: {
        email: "",
      },
    });
  }

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ isDirty: true });
    if (this.anyError()) return;

    apiService
      .subscribe({
        ...this.state.form,
      })
      .then((resp) => {
        this.clearForm();
        this.setState({
          isDirty: false,
          submitStatus: "success",
        });

        toast.dark("Дякуємо за підписку!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((e) => {
        this.setState({
          isDirty: false,
          submitStatus: "error",
        });
        console.error(e);
      });
  };

  render() {
    const { email } = this.state.form;

    return (
      <div className="subscribe">
        <ToastContainer />
        <div className="subscribe_text">Підпишіться на наші новини</div>
        <form className="subscribe_inputs" onSubmit={this.submitHandler}>
          <input
            type="email"
            required
            placeholder="Ваш Email"
            value={email}
            onChange={(e) => this.handleChange(e, "email")}
          />
          <button className="subscribe_button">Підписатись</button>
        </form>
      </div>
    );
  }
}

export default Subscribe;
