import React from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

class SingleCheckbox extends React.Component {
  render() {
    const { key, label, checked } = this.props.data;
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={!!checked}
            onChange={() => this.props.onChange({ key })}
            name="checkedA"
          />
        }
        label={label}
      />
    );
  }
}

SingleCheckbox.propTypes = {
  data: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    checked: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SingleCheckbox;
