import React from "react";
import * as _ from "lodash";
import HousePlan from "./HousePlan";
import { createBrowserHistory } from "../../history";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import style from "./House.module.scss";
import Meta from "../../Component/Meta/Meta";

class House extends React.Component {
  componentDidMount() {
    const paths = document.getElementsByClassName("floor");
    _.forEach(paths, (path) =>
      path.addEventListener("click", () => {
        this.props.history.push("/house/floor-plan");
      })
    );
  }

  render() {
    return (
      <div className={style.house}>
        <Meta title={"ОБЕРІТЬ ПОВЕРХ"} />

        <Breadcrumbs current="БУДИНОК" blackMode />
        <div className={style.houseTitle}>ОБЕРІТЬ ПОВЕРХ</div>
        <HousePlan />
      </div>
    );
  }
}

export default House;
