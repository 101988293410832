import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo";
import "./Header.css";
import Feedback from "../Feedback/Feedback";
import { LiteralsContext } from "../../context";

class Header extends React.Component {
  state = {
    isHeaderMenuOpen: false,
    popupIsOpen: false,
  };

  popupHandle = () => {
    this.setState({
      popupIsOpen: false,
    });
    document.body.style.overflow = "visible";
  };

  popupOpen = () => {
    document.body.style.paddingRight = "0px";
    const isMobile = window.screen.width <= 1024;
    this.setState(
      {
        isHeaderMenuOpen: false,
        popupIsOpen: true,
      },
      () => {
        if (isMobile) {
          document.body.style.overflow = "hidden";
        }
      }
    );
  };

  onMenuLinkChange = () => {
    this.setState({ isHeaderMenuOpen: !this.state.isHeaderMenuOpen });
    document.body.style.overflow = "visible";
    document.body.style.paddingRight = "0px";
  };

  onMenuChange = () => {
    const isMobile = window.screen.width <= 1024;
    this.setState({ isHeaderMenuOpen: !this.state.isHeaderMenuOpen }, () => {
      this.state.isHeaderMenuOpen
        ? (document.body.style.overflow = "hidden")
        : (document.body.style.overflow = "visible");
      if (!isMobile) {
        this.state.isHeaderMenuOpen
          ? (document.body.style.paddingRight = "17px")
          : (document.body.style.paddingRight = "0px");
      }
    });
  };

  render() {
    const { contact_info, social_links } = this.context;
    return (
      <div className="header">
        {this.state.popupIsOpen && <Feedback handle={this.popupHandle} />}
        <div
          className={
            this.state.isHeaderMenuOpen
              ? "header_menu"
              : "header_menu menu-hide"
          }>
          <div className="header_menu_left">
            <div className="footer_sitemap-info">
              <div className="footer_sitemap-left">
                <Link
                  onClick={() => this.onMenuLinkChange()}
                  to="/about-the-complex"
                  className="footer_sitemap-item">
                  Про комплекс
                </Link>
                <Link
                  onClick={() => this.onMenuLinkChange()}
                  to="/house"
                  className="footer_sitemap-item">
                  Вибір планування
                </Link>
                <Link
                  onClick={() => this.onMenuLinkChange()}
                  to="/infrastructure"
                  className="footer_sitemap-item">
                  Інфраструктура
                </Link>
                <Link
                  onClick={() => this.onMenuLinkChange()}
                  to="/construction-progress"
                  className="footer_sitemap-item">
                  Хід будівництва
                </Link>
                <Link
                  onClick={() => this.onMenuLinkChange()}
                  to="/about-developer"
                  className="footer_sitemap-item">
                  Забудовник
                </Link>
                <Link
                  onClick={() => this.onMenuLinkChange()}
                  to="/construction-technology"
                  className="footer_sitemap-item">
                  Технології будівництва
                </Link>
              </div>
              <div className="footer_sitemap-right">
                {/* <Link
                  onClick={() => this.onMenuLinkChange()}
                  to="/parking"
                  className="footer_sitemap-item"
                >
                  Паркінг
                </Link> */}
                <Link
                  onClick={() => this.onMenuLinkChange()}
                  to="/commercial-property"
                  className="footer_sitemap-item">
                  Комерційна нерухомість
                </Link>
                <Link
                  onClick={() => this.onMenuLinkChange()}
                  to="/layout"
                  className="footer_sitemap-item">
                  Квартири за параметрами
                </Link>
                {/* <Link
                  onClick={() => this.onMenuLinkChange()}
                  to="/documents"
                  className="footer_sitemap-item"
                >
                  Документи
                </Link> */}
                {/* <Link
                  onClick={() => this.onMenuLinkChange()}
                  to="/terms-of-purchase"
                  className="footer_sitemap-item"
                >
                  Умови придбання
                </Link> */}
                <Link
                  onClick={() => this.onMenuLinkChange()}
                  to="/news"
                  className="footer_sitemap-item">
                  Новини та акції
                </Link>
                <Link
                  onClick={() => this.onMenuLinkChange()}
                  to="/gallery"
                  className="footer_sitemap-item">
                  Галерея
                </Link>
                <Link
                  onClick={() => this.onMenuLinkChange()}
                  to="/contacts"
                  className="footer_sitemap-item">
                  Контакти
                </Link>
              </div>
            </div>
          </div>
          <div className="header_menu_right">
            <div
              className="header_closeButton"
              onClick={() => this.onMenuChange()}></div>
            <div className="menu-right-title">Офіс продажу:</div>
            {contact_info.menu_sales_office_address && (
              <div
                className="menu-right-text"
                dangerouslySetInnerHTML={{
                  __html: contact_info.menu_sales_office_address.content,
                }}></div>
            )}
            <div className="menu-right-title">Зв'язок з нами:</div>
            <div className="menu-right-text">
              {contact_info.email && (
                <a href={`mailto:${contact_info.email.content}`}>
                  {contact_info.email.content}
                </a>
              )}
              <br />
              {contact_info.phone_link_value && (
                <a href={`tel:${contact_info.phone_link_value.content}`}>
                  {contact_info.phone_label.content}
                </a>
              )}
            </div>
            <div className="menu-right-button" onClick={() => this.popupOpen()}>
              Замовити дзвінок
            </div>
            <div className="menu-right-icons">
              {social_links.facebook && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={social_links.facebook.content}
                  className="menu-right-wrap">
                  <div className="menu-right-facebook" />
                </a>
              )}
              {social_links.instagram && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={social_links.instagram.content}
                  className="menu-right-wrap">
                  <div className="menu-right-instagram" />
                </a>
              )}
              {social_links.telegram && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={social_links.telegram.content}
                  className="menu-right-wrap">
                  <div className="menu-right-telegram" />
                </a>
              )}
              {social_links.youtube && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={social_links.youtube.content}
                  className="menu-right-wrap">
                  <div className="menu-right-youtube" />
                </a>
              )}
            </div>
          </div>
          <div className="header_menu_bottomMobile">
            <div className="header_mobileContactTop">
              <div className="menu-right-title">Офіс продажу:</div>
              <div className="menu-right-text">
                {contact_info.address && contact_info.address.content}
              </div>
            </div>
            <div className="header_mobileContactBot">
              <div className="header_contactBot-left">
                {contact_info.address_map_link && (
                  <a
                    href={contact_info.address_map_link.content}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer_contact-map">
                    Дивитись на карті
                  </a>
                )}
                <div className="menu-right-text">
                  {contact_info.email && (
                    <a href={`mailto:${contact_info.email.content}`}>
                      {contact_info.email.content}
                    </a>
                  )}
                  <br />
                  {contact_info.phone_label && (
                    <a href={`tel:${contact_info.phone_link_value.content}`}>
                      {contact_info.phone_label.content}
                    </a>
                  )}
                </div>
              </div>
              <div>
                <div className="menu-right-icons">
                  {social_links.facebook && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={social_links.facebook.content}
                      className="menu-right-wrap">
                      <div className="menu-right-facebook" />
                    </a>
                  )}
                  {social_links.instagram && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={social_links.instagram.content}
                      className="menu-right-wrap">
                      <div className="menu-right-instagram" />
                    </a>
                  )}
                  {social_links.telegram && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={social_links.telegram.content}
                      className="menu-right-wrap">
                      <div className="menu-right-telegram" />
                    </a>
                  )}
                  {social_links.youtube && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={social_links.youtube.content}
                      className="menu-right-wrap">
                      <div className="menu-right-youtube" />
                    </a>
                  )}
                </div>
                <div
                  className="menu-right-button"
                  onClick={() => this.popupOpen()}>
                  Замовити дзвінок
                </div>
              </div>
            </div>
            <div
              className="header_closeButton"
              onClick={() => this.onMenuChange()}></div>
          </div>
        </div>
        <Logo />
        <div className="header_info">
          <div
            className="header-call-btn menu-right-button"
            onClick={() => this.popupOpen()}>
            Замовити дзвінок
          </div>
          {contact_info.address && (
            <a
              href={contact_info.address_map_link.content}
              target="_blank"
              rel="noopener noreferrer"
              className="header_infoMapWrapper">
              <div className="header_info-location" />
              <span className="header_info-address">
                {contact_info.address.content}
              </span>
            </a>
          )}
          {contact_info.phone_label && (
            <div className="header_info-phoneWrapper">
              <div className="header_info-phoneIcon" />
              <a
                href={`tel:${contact_info.phone_link_value.content}`}
                className={cn("header_info-phone " + "ad_saver_phone")}>
                {contact_info.phone_label.content}
              </a>
            </div>
          )}

          <div
            className="header_menu-button"
            onClick={() => this.onMenuChange()}>
            <div className="header_menu-linesWrapper">
              <div className="header_menu-line" />
              <div className="header_menu-line" />
              <div className="header_menu-shortLine" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Header.contextType = LiteralsContext;

export default Header;
