import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo";
import "./Footer.css";
import Feedback from "../Feedback/Feedback";
import { LiteralsContext } from "../../context";

class Footer extends React.Component {
  state = {
    popupIsOpen: false,
  };

  popupHandle = () => {
    this.setState({
      popupIsOpen: false,
    });
    document.body.style.overflow = "visible";
  };

  popupOpen = () => {
    const isMobile = window.screen.width <= 1024;
    this.setState(
      {
        popupIsOpen: true,
      },
      () => {
        document.body.style.overflow = "hidden";
      }
    );
  };

  render() {
    const { footer, contact_info, social_links } = this.context;
    return (
      <div className="footer">
        {this.state.popupIsOpen && <Feedback handle={this.popupHandle} />}
        <div className="footer_top">
          <Logo />
          <div className="footer-left">
            <div className="footer_contact-title">Контакти</div>
            <div className="footer_contact-info">
              <div className="footer_contact-text">
                {contact_info.footer_sales_office_address && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: contact_info.footer_sales_office_address.content,
                    }}></div>
                )}
                <a
                  target="_blank"
                  href="https://maps.google.com/maps?q=ЖК+Creator+City"
                  className="footer_contact-map">
                  Дивитись на карті
                </a>
                <div className="socialIcons_wrapper">
                  {social_links.facebook && (
                    <a
                      target="_blank"
                      href={social_links.facebook.content}
                      className="social-facebook"
                    />
                  )}
                  {social_links.instagram && (
                    <a
                      target="_blank"
                      href={social_links.instagram.content}
                      className="social-instagram"
                    />
                  )}
                  {social_links.telegram && (
                    <a
                      target="_blank"
                      href={social_links.telegram.content}
                      className="social-telegram"
                    />
                  )}
                  {social_links.youtube && (
                    <a
                      target="_blank"
                      href={social_links.youtube.content}
                      className="social-youtube"
                    />
                  )}
                </div>
              </div>
              <div className="footer_contact-text">
                {contact_info.email_sales_manager && (
                  <a
                    className="text-mail"
                    href={`mailto:${contact_info.email_sales_manager.content}`}>
                    {contact_info.email_sales_manager.content}
                  </a>
                )}{" "}
                <br />
                {contact_info.phone_label && (
                  <a
                    href={`tel:${contact_info.phone_link_value.content}`}
                    className={cn("footer_contact-number " + "ad_saver_phone")}>
                    {contact_info.phone_label.content}
                  </a>
                )}
                <div
                  onClick={() => this.popupOpen()}
                  className="footer_contact-call">
                  Замовити дзвінок
                </div>
              </div>
            </div>
          </div>
          <div className="footer-right">
            <div className="footer_sitemap-title">Карта сайту</div>
            <div className="footer_sitemap-info">
              <div className="footer_sitemap-left">
                <Link to="/about-the-complex" className="footer_sitemap-item">
                  Про комплекс
                </Link>
                <Link to="/house" className="footer_sitemap-item">
                  Вибір планування
                </Link>
                <Link to="/infrastructure" className="footer_sitemap-item">
                  Інфраструктура
                </Link>
                <Link
                  to="/construction-progress"
                  className="footer_sitemap-item">
                  Хід будівництва
                </Link>
                <Link to="/about-developer" className="footer_sitemap-item">
                  Забудовник
                </Link>
                <Link
                  to="/construction-technology"
                  className="footer_sitemap-item">
                  Технології будівництва
                </Link>
              </div>
              <div className="footer_sitemap-right">
                {/* <Link to="/parking" className="footer_sitemap-item">
                  Паркінг
                </Link> */}
                <Link to="/commercial-property" className="footer_sitemap-item">
                  Комерційна нерухомість
                </Link>
                <Link to="/layout" className="footer_sitemap-item">
                  Квартири за параметрами
                </Link>
                {/* <Link to="/documents" className="footer_sitemap-item">
                  Документи
                </Link> */}
                {/* <Link to="/terms-of-purchase" className="footer_sitemap-item">
                  Умови придбання
                </Link> */}
                <Link to="/news" className="footer_sitemap-item">
                  Новини та акції
                </Link>
                <Link to="/gallery" className="footer_sitemap-item">
                  Галерея
                </Link>
                <Link to="/contacts" className="footer_sitemap-item">
                  Контакти
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div>{footer.copyright && footer.copyright.content}</div>
          {footer.designed_by && (
            <div
              dangerouslySetInnerHTML={{
                __html: footer.designed_by.content,
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
Footer.contextType = LiteralsContext;

export default Footer;
