import React from "react";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import style from "./ConstructionTechnology.module.scss";
import apiService from "../../services/apiService";
import Meta from "../../Component/Meta/Meta";

class ConstructionTechnology extends React.Component {
  state = {
    data: {
      meta: {},
      media: {},
    },
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    apiService
      .construction()
      .then((resp) => {
        this.setState({
          data: resp.data,
        });
      })
      .catch((error) => {
        console.error("fetchContacts", error);
        throw error;
      });
  };

  render() {
    const { title, media, meta } = this.state.data;

    return (
      <>
        <Meta title={title} image={media.infrastructure_banner} />

        <div className={style.technology}>
          <Breadcrumbs current={title} />
          <div
            className={style.technologyImage}
            style={{ backgroundImage: `url('${media.infrastructure_banner}')` }}
          />
          <div className={style.technologyMainText}>
            <div className={style.technologyTitle}>
              {meta.first_block_title}
            </div>
            <div
              className={style.technologyText}
              dangerouslySetInnerHTML={{
                __html: meta.first_block_content,
              }}
            ></div>
          </div>
        </div>
        <div className={style.technologyFirstBlock}>
          <div
            className={style.firstBlockImage}
            style={{
              backgroundImage: `url('${media.infrastructure_second_block}')`,
            }}
          />
          <div className={style.firstBlockText}>
            <div className={style.textTitle}>{meta.second_block_title}</div>
            <div
              className={style.rich}
              dangerouslySetInnerHTML={{
                __html: meta.second_block_content,
              }}
            ></div>
          </div>
        </div>
        <div className={style.specifications}>
          <div className={style.specificationLeft}>
            <div className={style.leftTitle}>{meta.third_block_title}</div>
            <div
              className={style.rich}
              dangerouslySetInnerHTML={{
                __html: meta.third_block_content,
              }}
            ></div>
          </div>
          <div
            className={style.specificationRight}
            style={{
              backgroundImage: `url('${media.infrastructure_third_block}')`,
            }}
          />
        </div>
      </>
    );
  }
}

export default ConstructionTechnology;
