import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import style from "./../../Container/CommercialProperty/CommercialProperty.module.scss";

class MultiplyCheckbox extends React.Component {
  render() {
    const { mappedList, key, label } = this.props.data;
    return (
      <div className={style.filterRooms}>
        <div
          className={style.roomsTitle}
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        ></div>
        <div className={style.roomsWrapper}>
          {mappedList.map((item) => (
            <div
              key={item.key}
              className={cn(style.roomsButton, {
                [style.active]: item.isChecked,
              })}
              onClick={() => this.props.onChange({ key, item: { ...item } })}
            >
              {item.value}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

MultiplyCheckbox.propTypes = {
  data: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    values: PropTypes.object,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiplyCheckbox;
