import React from "react";
import OtherSlider from "../../Component/OtherSlider/OtherSlider";
import Subscribe from "../../Component/Subscribe/Subscribe";
import { Link } from "react-router-dom";
import style from "./AboutDeveloper.module.scss";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import apiService from "../../services/apiService";
import moment from "moment";
import Meta from "../../Component/Meta/Meta";

class AboutDeveloper extends React.Component {
  state = {
    data: {
      meta: {},
      media: {},
    },
    anotherProjects: [],
    articles: {
      data: null,
    },
  };

  componentDidMount() {
    this.fetchData();
    this.fetchNews();
  }

  fetchData = () => {
    apiService
      .developer()
      .then((resp) => {
        this.setState({
          data: resp.data,
        });
      })
      .catch((error) => {
        console.error("fetchAboutDeveloper", error);
        throw error;
      });

    apiService
      .anotherProjects()
      .then((resp) => {
        this.setState({
          anotherProjects: resp.data,
        });
      })
      .catch((error) => {
        console.error("anotherProjects", error);
        throw error;
      });
  };

  fetchNews = () => {
    apiService
      .articles(3)
      .then((resp) => {
        this.setState({
          articles: resp.data,
        });
      })
      .catch((error) => {
        console.error("fetchNews", error);
        throw error;
      });
  };

  render() {
    const { meta, media } = this.state.data;
    const { articles, anotherProjects } = this.state;
    return (
      <>
        <Meta title={this.state.data.title} image={media.developer_banner} />

        <div className={style.aboutDeveloper}>
          <Breadcrumbs current={this.state.data.title} />
          <div
            className={style.aboutImage}
            style={{ backgroundImage: `url('${media.developer_banner}')` }}
          />
          <div className={style.aboutTitleBlock}>
            <div className={style.titleLeft}>
              <div className={style.title}>{meta.first_block_title}</div>
              <div className={style.logo} />
            </div>
            <div className={style.titleRight}>
              <div
                className={style.text}
                dangerouslySetInnerHTML={{
                  __html: meta.first_block_content,
                }}
              ></div>
              <a
                href="http://www.kreator-bud.com/"
                target="_blank"
                className={style.button}
              >
                {meta.first_block_btn_title}
              </a>
            </div>
          </div>
        </div>
        <div className={style.firstBlock}>
          <div
            className={style.firstImage}
            style={{
              backgroundImage: `url('${media.developer_second_block_image}')`,
            }}
          />
          <div className={style.firstWrapper}>
            <div className={style.firstInfro}>
              <div className={style.infoItem}>
                <div className={style.itemTitle}>
                  {meta.second_block_foundation_year}
                </div>
                <div className={style.itemText}>Рік заснування</div>
              </div>
              <div className={style.infoItem}>
                <div className={style.itemTitle}>
                  {meta.second_block_already_built}
                </div>
                <div className={style.itemText}>Побудовано</div>
              </div>
              <div className={style.infoItem}>
                <div className={style.itemTitle}>
                  {meta.second_block_building_progress}
                </div>
                <div className={style.itemText}>Будується</div>
              </div>
            </div>
            <div
              className={style.firstText}
              dangerouslySetInnerHTML={{
                __html: meta.second_block_content,
              }}
            ></div>
          </div>
        </div>
        <div className={style.secondBlock}>
          <div className={style.secondTop}>
            <div className={style.toptextWrapper}>
              <div className={style.topTitle}>{meta.third_block_title}</div>
              <div
                className={style.topText}
                dangerouslySetInnerHTML={{
                  __html: meta.third_block_content,
                }}
              ></div>
            </div>
            <div
              className={style.topImage}
              style={{
                backgroundImage: `url('${media.developer_third_block_image}')`,
              }}
            />
          </div>
          <div className={style.secondBottom}>
            <div
              className={style.botImage}
              style={{
                backgroundImage: `url('${media.developer_fourth_block_image}')`,
              }}
            />
            <div className={style.toptextWrapper}>
              <div className={style.topTitle}>{meta.forth_block_title}</div>
              <div
                className={style.topText}
                dangerouslySetInnerHTML={{
                  __html: meta.forth_block_content,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="landing_news">
          <div className="landing_news-header">
            <div className="landing_news-title">Новини</div>
            <div className="landing_news-wrapper">
              <Link to="/news" className="landing_news-all">
                Усі новини
              </Link>
              <div className="landing_news-arrowIcon" />
            </div>
          </div>
          <div className="landing_news-itemWrapper">
            {articles.data &&
              articles.data.map((item) => (
                <Link
                  to={`/news/${item.slug}`}
                  className="landing_news-item"
                  key={item.slug}
                >
                  <img src={item.banner} className="landing_news-firstImage" />
                  <div className="landing_news-date">
                    {moment(item.published_at).format("DD/MM/YYYY")}
                  </div>
                  <div className="landing_news-text">{item.title}</div>
                  <div className="landing_news-arrowIcon" />
                </Link>
              ))}
          </div>
        </div>
        <Subscribe />
        <div className="landing_other">
          <div className="landing-other-left">
            <div className="landing_other-title">
              <div className="landing_other-line" />
              <div className="landing_other-titleText">ПРОЕКТИ</div>
            </div>
            <div className="landing_other-subtitle">
              Інші проекти
              <br />
              від забудовника
              <br />
              &#171;Креатор-Буд&#187;
              <div className="landing_other-logo" />
            </div>
          </div>
          <div className="landing-other-right">
            <OtherSlider projects={anotherProjects} />
            <div className="landing-other-rightWrapper">
              <div className="landing-other-wrapper">
                <div className="landing-house-buttonWrapper">
                  <div className="landing-house-ArrowButton">&#8594;</div>
                  <div className="landing-house-ArrowButton">&#8592;</div>
                </div>
              </div>
              {anotherProjects &&
                anotherProjects.map((item) => (
                  <a
                    key={item.id}
                    href={item.site_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="landing-other-projectBlock"
                  >
                    <div
                      className="landing-other-imageFirst"
                      style={{ backgroundImage: `url('${item.image}')` }}
                    />
                    <div className="landing-other-imageText">{item.title}</div>
                  </a>
                ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutDeveloper;
