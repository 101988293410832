import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import Gallery from "../../Component/Gallery/Gallery";
import GalleryPopup from "../../Component/GalleryPopup/GalleryPopup";
import style from "./AboutComplex.module.scss";
import apiService from "../../services/apiService";
import planImage from '../../Images/gallery5.jpg';
import Meta from "../../Component/Meta/Meta";

class AboutComplex extends React.Component {
  state = {
    popupIsOpen: false,
    planPopupIsOpen: false,
    imageId: 0,
    data: {
      meta: {},
      media: {},
    },
  };

  componentDidMount() {
    this.fetchData();
  }

  onPlanPopupChange = () => {
    this.setState({
      planPopupIsOpen: !this.state.planPopupIsOpen
    }, () => {
      this.state.planPopupIsOpen ? document.body.style.overflow = "hidden" : document.body.style.overflow = "visible";
    })
  }

  fetchData = () => {
    apiService
      .about()
      .then((resp) => {
        this.setState({
          data: resp.data,
        });
      })
      .catch((error) => {
        console.error("fetchAbout", error);
        throw error;
      });
  };

  popupOpen = (id) => {
    this.setState({
      popupIsOpen: true,
      imageId: id,
    });
    document.body.style.overflow = "hidden";
  };

  onPopupChange = () => {
    this.setState({
      popupIsOpen: !this.state.popupIsOpen,
    });
    document.body.style.overflow = "visible";
  };

  render() {
    const { meta, media } = this.state.data;
    return (
      <>
      <Meta title={this.state.data.title} image={media.about_first_block} />

        {this.state.planPopupIsOpen && <div className={style.planPopup}>
            <img src={media.about_second_block_genplan} alt="genplan"/>
            <div className={style.planCrossWrapper}>
            <div
              className={style.planCross}
              onClick={() => {
                this.onPlanPopupChange();
              }}
            />
          </div>
          </div>}
        <div className={style.aboutComplex}>
          {this.state.popupIsOpen && (
            <GalleryPopup
              imageType={this.state.imageId}
              type="popup"
              handle={this.onPopupChange}
            />
          )}
          <Breadcrumbs current={this.state.data.title} />
          <div
            className={style.complexImage}
            style={{ backgroundImage: `url('${media.about_first_block}')` }}
          />
          <div className={style.complexHeader}>
            <div className={style.complexTitle}>{meta.first_block_title}</div>
            <div
              className={style.complexText}
              dangerouslySetInnerHTML={{
                __html: meta.first_block_content,
              }}
            ></div>
          </div>
        </div>
        <div className={style.firstBlock}>
          <div
            className={style.firstImage}
            style={{ backgroundImage: `url('${media.about_second_block}')` }}
          />
          <div className={style.firstWrapper}>
            <div className={style.firstInfro}>
              <div className={style.infoItem}>
                <div className={style.itemTitle}>
                  {meta.second_block_class_complex}
                </div>
                <div className={style.itemText}>Клас ЖК</div>
              </div>
              <div className={style.infoItem}>
                <div className={style.itemTitle}>
                  {meta.second_block_height_ceiling}
                </div>
                <div className={style.itemText}>Висота стелі</div>
              </div>
              <div className={style.infoItem}>
                <div className={style.itemTitle}>
                  {meta.second_block_count_floors}
                </div>
                <div className={style.itemText}>Поверхів</div>
              </div>
            </div>
            <div className={style.textWrapper}>
              <div className={style.firstText}>
                {meta.second_block_content}
                <div className={style.highlightText}>
                  {meta.second_block_content_highlight}
                </div>
              </div>
              <Link to="/construction-technology" className={style.firstButton}>
                {meta.second_block_btn_title}
              </Link>
              {/* <div onClick={() => this.onPlanPopupChange()} className={style.firstButton}>
                {meta.second_block_btn_title}
              </div> */}
            </div>
          </div>
        </div>
        <div className={style.secondBlock}>
          <div className={style.secondLeft}>
            <div className={style.leftTitle}>{meta.third_block_title}</div>
            <div
              className={style.secondText}
              dangerouslySetInnerHTML={{
                __html: meta.third_block_content,
              }}
            ></div>
            <Link to="/about-developer" className={style.secondButton}>
              {meta.third_block_btn_title}
            </Link>
          </div>
          <div
            className={style.secondRightImage}
            style={{ backgroundImage: `url('${media.about_third_block}')` }}
          >
            <div className={style.imageLabel}>
              <div className={style.logo} />
            </div>
          </div>
        </div>
        <div className={style.thirdBlock}>
          <div className={style.thirdTop}>
            <div
              className={style.thirdImage}
              style={{ backgroundImage: `url('${media.about_fourth_block}')` }}
            />
            <div className={style.thirdInfo}>
              <div className={style.infoTitle}>{meta.fourth_block_title}</div>
              <div
                className={style.infoText}
                dangerouslySetInnerHTML={{
                  __html: meta.fourth_block_content,
                }}
              ></div>
            </div>
          </div>
          <div className={style.thirdMiddle}>
            <div className={style.thirdInfo}>
              <div className={style.infoTitle}>{meta.fifth_block_title}</div>
              <div
                className={style.infoText}
                dangerouslySetInnerHTML={{
                  __html: meta.fifth_block_content,
                }}
              ></div>
            </div>
            <div
              className={style.middleImage}
              style={{ backgroundImage: `url('${media.about_fifth_block}')` }}
            />
          </div>
          <div className={style.thirdBottom}>
            <div
              className={style.botImage}
              style={{ backgroundImage: `url('${media.about_six_block}')` }}
            />
            <div className={style.thirdInfo}>
              <div className={style.infoTitle}>{meta.six_block_title}</div>
              <div
                className={style.infoText}
                dangerouslySetInnerHTML={{
                  __html: meta.six_block_content,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className={style.fourthBlock}>
          <div
            className={style.fourthText}
            dangerouslySetInnerHTML={{
              __html: meta.seven_block_content,
            }}
          ></div>
          <Link to="/infrastructure" className={style.fourthButton}>
            {meta.seven_block_btn_title}
          </Link>
        </div>
        <div className={style.fifthBlock}>
          <div className={style.fifthInfo}>
            <div className={style.fifthTitle}>{meta.eight_block_title}</div>
            <div
              className={style.fifthText}
              dangerouslySetInnerHTML={{
                __html: meta.eight_block_content,
              }}
            ></div>
            <Link to="construction-technology" className={style.fifthButton}>
              {meta.eight_block_btn_title}
            </Link>
          </div>
          <div
            className={style.fifthImage}
            style={{ backgroundImage: `url('${media.about_eight_block}')` }}
          />
        </div>
        <div className="landing_gallery">
          <div className="landing_gallery-header">
            <div className="landing_gallery-title">Галерея</div>
            <Link to="/gallery" className="landing_gallery-wrapper">
              <div className="landing_gallery-all">
                <div className={style.PcText}>Дивитись усі</div>
                <div className={style.mobileText}>Усі</div> рендери
              </div>
              <div className="landing_gallery-arrowIcon" />
            </Link>
          </div>
          <div>
            <div className="landing-gallery-imagesWrapper">
              <div className="landing-gallery-nav">
                <div className="gallery_nav-arrow" />
                <div className="gallery_nav-arrowRotate" />
              </div>
            </div>
            {media.about_page_gallery && (
              <Gallery
                callback={this.popupOpen}
                slides={media.about_page_gallery}
              />
            )}

            <div>
              <div className="landing-gallery-progressLine">
                <div className="landing-gallery-currentProgress" />
              </div>
            </div>
          </div>
        </div>
        <div
          className={style.sixthBlock}
          style={{ backgroundImage: `url('${media.about_page_footer}')` }}
        >
          <iframe
            src="https://3dtour.ua/tours/2020/zhk_krauss_gallery/index.html"
            width="600"
            height="400"
          ></iframe>
        </div>
      </>
    );
  }
}

export default AboutComplex;
