import React from "react";
import style from "./GalleryPopup.module.scss";

class GalleryPopup extends React.Component {
  state = {
    tabValue: 0,
  };

  handleChange = (index) => {
    this.setState({ tabValue: index });
  };

  rednerTabInfo = () => {
    return (
      <div
        className={style.inImage1}
        style={{ backgroundImage: `url('${this.props.imageType}')` }}
      />
    );
  };

  render() {
    return (
      <div className={style.galleryPopup}>
        <div className={style.galleryInfoWrapper}>
          <div className={style.popupCrossWrapper}>
            <div
              className={style.popupCross}
              onClick={() => {
                this.props.handle();
              }}
            />
          </div>
          <div className={style.infoWrapper}>{this.rednerTabInfo()}</div>
        </div>
      </div>
    );
  }
}

export default GalleryPopup;
