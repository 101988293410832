import React from "react";
import style from "./FloorPlan.module.scss";
import PropTypes from "prop-types";
import imageMapResize from "image-map-resizer";

class ImageMap extends React.Component {
  componentDidMount() {
    const { floor_plan, plan_image_map } = this.props.floorPlan;
    if (!floor_plan || !plan_image_map) return;
    const img = document.querySelectorAll(".image-map img")[0];
    img.src = floor_plan;
    imageMapResize("map");

    const areaList = document.querySelectorAll("area");

    for (let i = 0; i < areaList.length; i++) {
      const $this = areaList[i];
      $this.addEventListener("mouseover", () => {
        this.drawPoly($this.getAttribute("coords"), "rgba(171,144,96,0.5)");
      });
    }

    for (let i = 0; i < areaList.length; i++) {
      const $this = areaList[i];
      $this.addEventListener("mouseleave", () => {
        const canvas = document.querySelector("canvas");
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
      });
    }
  }

  drawPoly = (coordinates, color) => {
    const canvas = document.querySelector("canvas");
    const parent = canvas.parentNode;
    canvas.width = parent.offsetWidth;
    canvas.height = parent.offsetHeight;
    const context = canvas.getContext("2d");

    context.fillStyle = color;
    context.lineWidth = 0;
    const mCoords = coordinates.split(",");
    const n = mCoords.length;
    context.beginPath();
    context.moveTo(mCoords[0], mCoords[1]);
    for (let i = 2; i < n; i += 2) {
      context.lineTo(mCoords[i], mCoords[i + 1]);
    }
    context.lineTo(mCoords[0], mCoords[1]);
    context.fill();
  };

  render() {
    const { plan_image_map, floor_plan } = this.props.floorPlan;

    if (!plan_image_map || !floor_plan) {
      return "Empty data";
    }
    return (
      <div className={style.floorImage}>
        <canvas></canvas>

        <div
          className="image-map"
          dangerouslySetInnerHTML={{
            __html: plan_image_map,
          }}
        ></div>
      </div>
    );
  }
}

ImageMap.propTypes = {
  floorPlan: PropTypes.shape({
    floor: PropTypes.number,
    floor_plan: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
    plan_image_map: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
  }).isRequired,
};

export default ImageMap;
