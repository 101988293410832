import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import Subscribe from "../../Component/Subscribe/Subscribe";
import style from "./News.module.scss";
import apiService from "../../services/apiService";
import moment from "moment";
import Meta from "../../Component/Meta/Meta";

class News extends React.Component {
  state = {
    data: {
      meta: {},
      data: [],
    },
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    apiService
      .articles()
      .then((resp) => {
        this.setState({
          data: resp.data,
        });
      })
      .catch((error) => {
        console.error("fetchNews", error);
        throw error;
      });
  };

  renderItems(isFirstLine = false) {
    const { data } = this.state.data;
    const slicedData = isFirstLine ? data.slice(0, 3) : data.slice(3);
    return (
      <>
        {slicedData &&
          slicedData.map((item) => (
            <Link
              to={`/news/${item.slug}`}
              className={style.newsItem}
              key={item.id}
            >
              <img src={item.banner} className={style.newsFirstImage} />
              <div className={style.newsDate}>
                {moment(item.published_at).format("DD/MM/YYYY")}
              </div>
              <div className={style.newsItemTitle}>{item.title}</div>
              <div className={style.newsArrow} />
            </Link>
          ))}
      </>
    );
  }

  render() {
    return (
      <>
        <Meta title={"НОВИНИ ТА АКЦІЇ"} />

        <div className={style.news}>
          <Breadcrumbs current="НОВИНИ ТА АКЦІЇ" />
          <div className={style.newsTitle}>НОВИНИ ТА АКЦІЇ</div>
          <div className={style.newsWrapper}>{this.renderItems(true)}</div>
        </div>
        <Subscribe />
        <div className={style.newsBot}>
          <div className={style.newsWrapper}>{this.renderItems()}</div>
        </div>
      </>
    );
  }
}

export default News;
