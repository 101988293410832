import { API_URL } from "./CONSTANTS";
import axios from "axios";

class ApiService {
  home() {
    return axios.get(`${API_URL}/pages/main_page`);
  }
  about() {
    return axios.get(`${API_URL}/pages/about`);
  }
  developer() {
    return axios.get(`${API_URL}/pages/developer`);
  }
  infrastructure() {
    return axios.get(`${API_URL}/pages/infrastructure`);
  }
  gallery() {
    return axios.get(`${API_URL}/pages/gallery`);
  }
  progress() {
    return axios.get(`${API_URL}/pages/progress`);
  }
  terms() {
    return axios.get(`${API_URL}/pages/terms`);
  }
  contacts() {
    return axios.get(`${API_URL}/pages/contacts`);
  }
  parking() {
    return axios.get(`${API_URL}/pages/parking`);
  }
  construction() {
    return axios.get(`${API_URL}/pages/construction-technology`);
  }
  articles(per_page = 999) {
    return axios.get(`${API_URL}/articles`, {
      params: {
        per_page,
      },
    });
  }
  article(slug) {
    return axios.get(`${API_URL}/articles/${slug}`);
  }
  constructionArticle(slug) {
    return axios.get(`${API_URL}/construction-progress/${slug}`);
  }
  documents() {
    return axios.get(`${API_URL}/pages/documents`);
  }
  //type = "flats" || "commercial_flats"
  flats(payload, type = "flats") {
    return axios.get(`${API_URL}/${type}`, {
      params: payload,
    });
  }
  //type = "flats" || "commercial_flats"
  flatsFilters(type = "flats") {
    return axios.get(`${API_URL}/${type}/filters`);
  }
  flat({ id }) {
    return axios.get(`${API_URL}/flats/${id}`);
  }
  literals() {
    return axios.get(`${API_URL}/literals`);
  }
  anotherProjects() {
    return axios.get(`${API_URL}/another-projects`);
  }
  floors() {
    return axios.get(`${API_URL}/floors`);
  }
  floor(id) {
    return axios.get(`${API_URL}/floors/${id}`);
  }
  feedback({ name, phone, email, message = "" }) {
    return axios.post(`${API_URL}/feedback`, {
      name,
      phone,
      email,
      message,
    });
  }
  subscribe({ email }) {
    return axios.post(`${API_URL}/subscribe`, {
      email,
    });
  }
}

const apiService = new ApiService();
export default apiService;
