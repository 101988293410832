import React from "react";
import style from "./FloorPlan.module.scss";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import apiService from "../../services/apiService";
import ImageMap from "./ImageMap";
import Meta from "../../Component/Meta/Meta";

class FloorPlan extends React.Component {
  state = {
    floors: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    apiService
      .floors()
      .then((resp) => {
        this.setState({
          floors: resp.data,
        });
      })
      .catch((error) => {
        console.error("fetchContacts", error);
        throw error;
      });
  };

  render() {
    const { id } = this.props.match.params;
    const floorPlan = this.state.floors.find((z) => z.floor === +id);
    return (
      <div className={style.floorPlan}>
        <Meta title={'ПЛАН ПОВЕРХУ'} />
        
        <Breadcrumbs
          current="ПЛАН ПОВЕРХУ"
          steps={3}
          secondStep={"БУДИНОК"}
          secondStepUrl={"/house"}
          blackMode
        />
        <div className={style.floorTitle}>ОБЕРІТЬ ПЛАНУВАННЯ</div>
        {floorPlan && <ImageMap floorPlan={floorPlan} />}
      </div>
    );
  }
}

export default FloorPlan;
