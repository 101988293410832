import React from "react";
import Helmet from "react-helmet";
import ImageBackup from "../../Images/introBotLanding.jpg";

function SEO({
  title = "Krauss: МИСТЕЦТВО БУТИ ОСОБИСТІСТЮ",
  image = `${window.location.origin}${ImageBackup}`,
}) {
  let url = window.location.href;

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
    </Helmet>
  );
}

export default SEO;
