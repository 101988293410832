import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper.scss";
import "./OtherSlider.css";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

class OtherSlider extends React.Component {
  render() {
    const { projects } = this.props;
    return (
      <div className="otherSlider">
        <Swiper
          spaceBetween={70}
          slidesPerView={2}
          navigation={true}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          breakpoints={{
            768: {
              slidesPerView: 1.5,
            },
            1024: {
              slidesPerView: 2,
            },
            1920: {
              spaceBetween: 150,
            },
          }}
        >
          {projects &&
            projects.map((item) => (
              <SwiperSlide key={item.id}>
                <a
                  href={item.site_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="landing-other-projectBlock"
                >
                  <div
                    className="landing-other-imageFirst"
                    style={{ backgroundImage: `url('${item.image}')` }}
                  />
                  <div className="landing-other-imageText">{item.title}</div>
                </a>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    );
  }
}

export default OtherSlider;
