import React from "react";
import * as _ from "lodash";
import cn from "classnames";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import style from "./Apartament.module.scss";
import Feedback from "../../Component/Feedback/Feedback";
import apiService from "../../services/apiService";
import Meta from "../../Component/Meta/Meta";

class Apartament extends React.Component {
  state = {
    apiType: this.props.match.path === "/layout" ? `flats` : `commercial_flats`,
    tabValue: 0,
    popupIsOpen: false,
    data: null,
    isFetching: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { id } = this.props.match.params;
    this.setState({
      isFetching: true,
    });
    apiService
      .flat({ id })
      .then((resp) => {
        this.setState({
          data: { ...resp.data },
          isFetching: false,
        });
      })
      .catch((error) => {
        this.setState({
          isFetching: false,
        });
        console.error("fetchApartament", error);
        throw error;
      });
  };

  handleChange = (index) => {
    this.setState({ tabValue: index });
  };

  rednerTabInfo = () => {
    const { flat_plan } = this.state.data;

    if (this.state.tabValue === 0) {
      return <img src={flat_plan.tech_plan} className={style.rightImage} />;
    }
    if (this.state.tabValue === 1) {
      return <img className={style.image3D} src={flat_plan["3d_plan"]} />;
    }
    if (this.state.tabValue === 2) {
      return <div className={style.imageWindowView} />;
    }
  };

  popupHandle = () => {
    this.setState({
      popupIsOpen: false,
    });
    document.body.style.overflow = "visible";
  };

  popupOpen = () => {
    const isMobile = window.screen.width <= 1024;
    this.setState(
      {
        popupIsOpen: true,
      },
      () => {
        if (isMobile) {
          document.body.style.overflow = "hidden";
        }
      }
    );
  };

  render() {
    const correctPath = _.includes(this.props.match.path, "/layout");
    const { data, isFetching } = this.state;

    if (!data || isFetching) return <div>Loading...</div>;

    const {
      flat_plan,
      price,
      total_area,
      floor,
      living_area,
      hallway_area,
      kitchen_area,
      bathroom_area,
      corridor_area,
      sanitary_area,
      promotion,
      file_pdf,
    } = data;

    return (
      <div className={style.apartament}>
        <Meta title={flat_plan.title} />

        {this.state.popupIsOpen && <Feedback handle={this.popupHandle} />}

        <Breadcrumbs
          current={flat_plan.title}
          steps={3}
          secondStep={
            correctPath ? "ПЛАНУВАННЯ КВАРТИР" : "КОМЕРЦІЙНА НЕРУХОМІСТЬ"
          }
          secondStepUrl={correctPath ? "/layout" : "/commercial-property"}
        />

        <div className={style.apartamentLeft}>
          {price && (
            <div className={style.leftHeader}>
              <div className={style.headerType}>{flat_plan.title}</div>
              {/* <div className={style.headerPrice}>Ціна {price} грн</div> */}
            </div>
          )}
          <div className={style.leftTitle}>
            {flat_plan.flat_type.rooms && (
              <div className={style.titleItem}>
                <div className={style.titleHead}>КІМНАТИ</div>
                <div className={style.titleValue}>
                  {flat_plan.flat_type.rooms}
                </div>
              </div>
            )}
            {floor.floor && (
              <div className={style.titleItem}>
                <div className={style.titleHead}>ПОВЕРХ</div>
                <div className={style.titleValue}>{floor.floor}</div>
              </div>
            )}
            {total_area && (
              <div className={style.titleItem}>
                <div className={style.titleHead}>ПЛОЩА</div>
                <div className={style.titleValue}>{total_area} м&#178;</div>
              </div>
            )}
          </div>
          <div className={style.leftMain}>
            <div className={style.mobileWrapper}>
              <div className={style.mobileGroup}>
                {living_area > 0 && (
                  <div className={style.specificationsWrapper}>
                    <div className={style.mainTitle}>Житлова площа</div>
                    <div className={style.line} />
                    <div className={style.specificationsValue}>
                      {living_area} м&#178;
                    </div>
                  </div>
                )}
                {hallway_area > 0 && (
                  <div className={style.specificationsWrapper}>
                    <div className={style.mainTitle}>Передпокій</div>
                    <div className={style.line} />
                    <div className={style.specificationsValue}>
                      {hallway_area} м&#178;
                    </div>
                  </div>
                )}
                {corridor_area > 0 && (
                  <div className={style.specificationsWrapper}>
                    <div className={style.mainTitle}>Коридор</div>
                    <div className={style.line} />
                    <div className={style.specificationsValue}>
                      {corridor_area} м&#178;
                    </div>
                  </div>
                )}
              </div>
              <div className={style.mobileGroup}>
                {bathroom_area > 0 && (
                  <div className={style.specificationsWrapper}>
                    <div className={style.mainTitle}>Ванна кімната</div>
                    <div className={style.line} />
                    <div className={style.specificationsValue}>
                      {bathroom_area} м&#178;
                    </div>
                  </div>
                )}
                {sanitary_area > 0 && (
                  <div className={style.specificationsWrapper}>
                    <div className={style.mainTitle}>Санвузол</div>
                    <div className={style.line} />
                    <div className={style.specificationsValue}>
                      {sanitary_area} м&#178;
                    </div>
                  </div>
                )}
                {kitchen_area > 0 && (
                  <div className={style.specificationsWrapper}>
                    <div className={style.mainTitle}>Кухня-вітальня</div>
                    <div className={style.line} />
                    <div className={style.specificationsValue}>
                      {kitchen_area} м&#178;
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={style.leftRequestButton}
            onClick={() => this.popupOpen()}
          >
            Залишити заявку
          </div>
          {file_pdf && (
            <div className={style.buttonsWrapper}>
              <a
                href={file_pdf}
                target="_blank"
                rel="noopener noreferrer"
                className={style.leftPdfButton}
              >
                Завантажити PDF
              </a>
            </div>
          )}
        </div>
        <div className={style.apartamentRight}>
          <div className={style.windIcon} />
          <div
            className={style.currenApartamentIcon}
            style={{ backgroundImage: `url('${flat_plan.plan_highlight}')` }}
          />
          {promotion && (
            <div className={style.saleBlock}>
              <div className={style.starIcon} />
              <div className={style.saleText}>{promotion.title}</div>
            </div>
          )}
          <div className={style.righHeader}>
            <div className={style.headerButtons}>
              <div
                className={cn(style.buttonTechnical, {
                  [style.buttonActive]: this.state.tabValue === 0,
                })}
                onClick={() => this.handleChange(0)}
              >
                Технічне планування
              </div>
              <div
                className={cn(style.button3D, {
                  [style.buttonActive]: this.state.tabValue === 1,
                })}
                onClick={() => this.handleChange(1)}
              >
                Планування з меблями
              </div>
            </div>
            {/* <div
              className={style.headerWindowButton}
              onClick={() => this.handleChange(2)}
            >
              Вид з вікна
            </div> */}
          </div>
          {this.rednerTabInfo()}
        </div>
      </div>
    );
  }
}

export default Apartament;
