import React from "react";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cn from "classnames";
import style from "./TermsOfPurchase.module.scss";
import apiService from "../../services/apiService";

class TermsOfPurchase extends React.Component {
  state = {
    tabValue: 0,
    data: {
      meta: {},
      media: {},
    },
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    apiService
      .terms()
      .then((resp) => {
        this.setState({
          data: resp.data,
        });
      })
      .catch((error) => {
        console.error("TermsOfPurchase", error);
        throw error;
      });
  };

  handleChange = (index) => {
    this.setState({ tabValue: index });
  };

  submitHandler = (e) => {
    e.preventDefault();
    toast.dark('Заявка прийнята!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  };

  rednerTabInfo = () => {
    const { meta } = this.state.data;
    if (this.state.tabValue === 0) {
      return (
        <div className={style.feedbackForm}>
          <ToastContainer />
          <div
            className={style.feedbackLeft}
            dangerouslySetInnerHTML={{
              __html: meta.buy_100_percent_content,
            }}
          ></div>
          <form className={style.feedbackRight} onSubmit={this.submitHandler}>
            <div className={style.feedbackTitle}>Зворотній зв'язок</div>
            <div className={style.rightInputs}>
              <input
                type="text"
                required
                placeholder="Ім'я"
                className={style.inputName}
              />
              <div className={style.rightShortInputs}>
                <input
                  type="tel"
                  required
                  placeholder="Телефон"
                  className={style.inputPhone}
                />
                <input
                  type="email"
                  required
                  placeholder="Email"
                  className={style.inputEmail}
                />
              </div>
              <textarea
                placeholder="Повідомлення"
                className={style.rightMessage}
              />
            </div>
            <button className={style.rightButton}>Відправити</button>
          </form>
        </div>
      );
    }
    if (this.state.tabValue === 1) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: meta.buy_100_percent_content,
          }}
        />
        // <div
        //   className={style.installmentsImage}
        //   dangerouslySetInnerHTML={{
        //     __html: meta.buy_100_percent_content,
        //   }}
        // />
      );
    }
  };

  render() {
    const { meta, media } = this.state.data;
    return (
      <>
        <div className={style.terms}>
          <Breadcrumbs current={this.state.data.title} />
          <div
            className={style.termsImage}
            style={{ backgroundImage: `url('${media.terms_banner}')` }}
          />
          <div className={style.termsTextWrapper}>
            <div className={style.termsTitle}>{meta.first_block_title}</div>
            <div
              className={style.termsText}
              dangerouslySetInnerHTML={{
                __html: meta.first_block_content,
              }}
            ></div>
          </div>
          <div className={style.feedbackNav}>
            <div
              className={cn(style.feedbackTab, {
                [style.activeButton]: this.state.tabValue === 0,
              })}
              onClick={() => this.handleChange(0)}
            >
              Купівля квартири при 100% оплаті
            </div>
            <div
              className={cn(style.feedbackTabMobile, {
                [style.activeButton]: this.state.tabValue === 0,
              })}
              onClick={() => this.handleChange(0)}
            >
              Купівля при 100% оплаті
            </div>
            <div
              className={cn(style.feedbackTabDisable, {
                [style.activeButton]: this.state.tabValue === 1,
              })}
              onClick={() => this.handleChange(1)}
            >
              Купівля квартири у розстрочку
            </div>
            <div
              className={cn(style.feedbackTabDisableMobile, {
                [style.activeButton]: this.state.tabValue === 1,
              })}
              onClick={() => this.handleChange(1)}
            >
              Розстрочку
            </div>
          </div>
        </div>
        <div className={style.feedback}>{this.rednerTabInfo()}</div>
      </>
    );
  }
}

export default TermsOfPurchase;
