import React from "react";
import ReactPlayer from "react-player";
import style from "./ConstructionProgress.module.scss";

import constructionVideo from "../../Images/constractionStatus.jpg";
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import { Link } from "react-router-dom";
import apiService from "../../services/apiService";
import moment from "moment";

class ConstructionProgress extends React.Component {
  state = {
    camera: 1,
    showMore: false,
    data: {
      meta: {},
      progresses: [],
    },
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    apiService
      .progress()
      .then((resp) => {
        this.setState({
          data: resp.data,
        });
      })
      .catch((error) => {
        console.error("fetchConstructionProgress", error);
        throw error;
      });
  };

  onCameraChange = (cameraType) => {
    this.setState({ camera: cameraType });
  };
  render() {
    const { meta, progresses } = this.state.data;
    return (
      <div className={style.constructionProgress}>
        <Breadcrumbs current={this.state.data.title} />
        <div className={style.top}>
          <div className={style.topLeft}>
            <div className={style.leftTitle}>{meta.first_block_title}</div>
            <div
              className={style.leftText}
              dangerouslySetInnerHTML={{
                __html: meta.first_block_content,
              }}
            ></div>
            <div className={style.leftButtons}>
              <div
                className={style.cameraButton}
                onClick={() => this.onCameraChange(1)}
              >
                Відео 1
              </div>
              <div
                className={style.cameraButton}
                onClick={() => this.onCameraChange(2)}
              >
                Відео 2
              </div>
            </div>
          </div>
          <div className={style.video}>
            <ReactPlayer
              className={style.player}
              url={this.state.camera === 1 ? meta.cam_1_link : meta.cam_2_link}
            />
          </div>
        </div>
        <div className={style.bottom}>
          <div className={style.otherWrapper}>
            {progresses &&
              progresses.map((item) => (
                <Link
                  to={`/construction-progress/${item.slug}`}
                  className={style.item}
                  key={item.id}
                >
                  <div
                    className={style.otherImage}
                    style={{ backgroundImage: `url('${item.banner}')` }}
                  />
                  <div className={style.date}>
                    {moment(item.published_at).format("DD/MM/YYYY")}
                  </div>
                  <div className={style.otherTitle}>{item.title}</div>
                  <div className={style.arrow} />
                </Link>
              ))}
          </div>
          {/* {this.state.showMore && (
            <div className={style.otherWrapper}>
              <Link to="/construction-progress" className={style.item}>
                <div className={style.otherImage} />
                <div className={style.date}>20/08/2020</div>
                <div className={style.otherTitle}>
                  Хід будівництва за серпень
                </div>
                <div className={style.arrow} />
              </Link>
            </div>
          )}
          {!this.state.showMore && (
            <div
              className={style.moreButton}
              onClick={() => this.setState({ showMore: true })}
            >
              Показати більше
            </div>
          )} */}
        </div>
      </div>
    );
  }
}

export default ConstructionProgress;
